import i18n from 'i18n.js';

export const scrollRequiredLabel =
  i18n.t('disclaimer:please_read_through', 'Please read through the text in full before proceeding');

export const checkboxRequiredLabel =
  i18n.t('disclaimer:checkbox_required', 'You must confirm acceptance by selecting the checkbox before proceeding');

export const requestFailed =
  i18n.t('common:errors.unexpected_error', 'An unexpected error occurred. Please try again.');
export const modalSubtitle =
  i18n.t('disclaimer:subtitle', 'Please read the following in full before proceeding');
