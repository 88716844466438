/* eslint-disable max-statements */
import { isBoolean } from 'lodash';
import {
  iaWithSeries65,
  insuranceAgent,
  trustOrFiduciary,
  advisorCrdNumber,
  registeredAsBDOrIA,
  totalAssets,
  isOtherTypeInstitution,
  isRegisteredRepresentative,
  firmRoleIfOther,
} from 'containers/registration/complete_profile/profileData';
import {
  getIsRegistrationSeriesFilled,
  getIsOtherTypeInstInvestorFilled,
} from 'containers/registration/complete_profile/TwoPageCompleteProfileValidations';
import { isTextFilled } from 'containers/registration/complete_profile/CompleteProfileValidations';
import { getRoleKey } from 'containers/registration/qualification_step/sections/attestationData';
import { validateAttestations } from 'containers/registration/qualification_step/QualificationValidations';

function calculateCanCompleteStepTwo(information) {
  return information.role === 'Other'
    ? isTextFilled(information[firmRoleIfOther.question_key])
    : isTextFilled(information.role);
}

function calculateCanCompleteStepThree(information) {
  const registrationSeriesFilled = getIsRegistrationSeriesFilled(information);
  const series65Filled = isBoolean(information[iaWithSeries65.question_key]);
  const crdFilled =
    (series65Filled &&
      !information[iaWithSeries65.question_key] &&
      registrationSeriesFilled &&
      !information[isRegisteredRepresentative.question_key]) ||
    isTextFilled(information[advisorCrdNumber.question_key]);
  const secFilled = isTextFilled(information[registeredAsBDOrIA.question_key]);

  return registrationSeriesFilled && series65Filled && crdFilled && secFilled;
}

function calculateCanCompleteStepFour(information) {
  const insuranceFilled = isBoolean(information[insuranceAgent.question_key]);
  const trustOrFiduciaryFilled = isBoolean(information[trustOrFiduciary.question_key]);
  const otherTypeInstitutionalInvestorFilled = getIsOtherTypeInstInvestorFilled(information);

  return insuranceFilled && trustOrFiduciaryFilled && otherTypeInstitutionalInvestorFilled;
}

function calculateCanCompleteStepFive(information) {
  const groupKey = getRoleKey(information.role);
  return validateAttestations(information, groupKey);
}

function calculateCanSubmit(information) {
  return (
    calculateCanCompleteStepThree(information) &&
    calculateCanCompleteStepFour(information) &&
    calculateCanCompleteStepFive(information)
  );
}

function calculateSfoCanSubmit(information) {
  const isRegisteredAsBdOrIaIsFilled = isTextFilled(information[registeredAsBDOrIA.question_key]);
  const isNotBdOrIa = isRegisteredAsBdOrIaIsFilled && information[registeredAsBDOrIA.question_key] === 'none';
  const isSeries65Filled = isBoolean(information[iaWithSeries65.question_key]);
  const hasSeries65 = information[iaWithSeries65.question_key];
  let isCrdFilled = false;
  let isInstitutionOver75MilFilled = false;
  let isTotalAssetsFilled = false;

  if (hasSeries65) {
    isCrdFilled = isTextFilled(information[advisorCrdNumber.question_key]);
  } else if (isSeries65Filled) {
    isCrdFilled = true;
  }

  if (isNotBdOrIa && hasSeries65 === false) {
    isInstitutionOver75MilFilled = isBoolean(information[isOtherTypeInstitution.question_key]);
    if (information[isOtherTypeInstitution.question_key] === false) {
      isTotalAssetsFilled = true;
    } else if (information[isOtherTypeInstitution.question_key]) {
      isTotalAssetsFilled = isTextFilled(information[totalAssets.question_key]);
    }
  } else {
    isInstitutionOver75MilFilled = true;
    isTotalAssetsFilled = true;
  }

  return (
    isRegisteredAsBdOrIaIsFilled &&
    isSeries65Filled &&
    isCrdFilled &&
    isInstitutionOver75MilFilled &&
    isTotalAssetsFilled
  );
}

export {
  calculateCanCompleteStepTwo,
  calculateCanCompleteStepThree,
  calculateCanCompleteStepFour,
  calculateCanCompleteStepFive,
  calculateCanSubmit,
  calculateSfoCanSubmit,
};
