import { shape, number, string, bool, arrayOf } from 'prop-types';

const accessAgreement = shape({
  agreementId: number,
  type: string,
  title: string,
  checkboxText: string,
  requireScrollToAccept: bool,
});

const accessAgreements = arrayOf(accessAgreement).isRequired;

export { accessAgreement, accessAgreements };
