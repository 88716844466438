/* eslint-disable max-statements */
import React, { PureComponent, Fragment } from 'react';
import { bool, oneOfType, arrayOf, node } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import IcnHeader from 'containers/icn_header/icnHeader';
import IcnFooter from 'containers/icn_footer/icnFooter';
import ConnectedImpersonationHeader from 'components/shared/impersonation_header/ImpersonationHeader';
import NotificationStoreContainer from 'components/shared/notifications/NotificationStoreContainer';
import OneTimePopup from 'components/one_time_popup/OneTimePopup';
import TermsOfUsePopup from 'components/terms_of_use_popup/TermsOfUsePopup';
import userShape from 'prop_types/shared/user';
import * as icnBootstrapSelectors from 'selectors/icnBootstrapSelectors';
import icnIdentity from 'services/icn_identity/icnIdentity';
import IncidentStatusAlerts from 'components/shared/incident_status_alerts';

import QualificationPopup from 'components/qualification_popup/QualificationPopup';
import IdentifyUserPopup from 'containers/registration/user_identification/IdentifyUserPopup';
import { isEmpty } from 'lodash';
import icnReactBootstrapShape from 'prop_types/shared/icn_react_bootstrap';
import styles from './LayoutGlobal.module.scss';

export class LayoutGlobal extends PureComponent {
  render() {
    const { user, isWlp, isImposter, children, hideFooter, hideBanners, icnReactBootstrap } = this.props;
    let showTerms = false;
    let showIqs = false;
    let showUserIdentification = false;
    const userIsRia = icnIdentity.isRia(user);
    const userIsSfo = icnIdentity.isSfo(user);
    const userNeedsUserIdentification =
      user?.identification_user_modal || user?.user_acknowledgements?.financial_professional;

    if (user) {
      showTerms =
        ((!user.active_main_site_terms_accepted && (!isWlp || (isWlp && !user.enable_custom_wlp_page))) ||
          (isWlp && user.wlp_has_terms && !user.active_wlp_terms_accepted)) &&
        !user.non_user;

      showIqs =
        icnReactBootstrap.feature_flags.enable_registration_marketplace &&
        user.isInvestor &&
        isEmpty(user.investor_qualification_standard_ids) &&
        !showTerms &&
        !!user.registered_date;

      showUserIdentification = userNeedsUserIdentification && (userIsRia || userIsSfo);
    }

    return (
      <div className={styles.LayoutGlobal}>
        <NotificationStoreContainer />
        {user && (
          <Fragment>
            <IcnHeader />
            {!isImposter && (
              <>
                <OneTimePopup />
                <TermsOfUsePopup isOpen={showTerms} />
                {showIqs && <QualificationPopup isOpen={showIqs} />}
                {showUserIdentification && (
                  <IdentifyUserPopup user={user} userIsRia={userIsRia} userIsSfo={userIsSfo} />
                )}
              </>
            )}
            {isImposter && !hideBanners && <ConnectedImpersonationHeader />}
            {!hideBanners && <IncidentStatusAlerts />}
          </Fragment>
        )}
        {children}
        {hideFooter ? null : <IcnFooter />}
      </div>
    );
  }
}

LayoutGlobal.propTypes = {
  user: userShape,
  isImposter: bool.isRequired,
  isWlp: bool.isRequired,
  children: oneOfType([arrayOf(node), node]),
  hideFooter: bool,
  hideBanners: bool,
  icnReactBootstrap: icnReactBootstrapShape,
};

LayoutGlobal.defaultProps = {
  user: null,
  children: '',
  hideFooter: false,
  hideBanners: false,
  icnReactBootstrap: {},
};

function mapStateToProps(state) {
  const user = icnBootstrapSelectors.getUser(state);
  const isImposter = icnBootstrapSelectors.isImposter(state);
  const isWlp = icnBootstrapSelectors.isWhiteLabelPartner(state);
  const icnReactBootstrap = icnBootstrapSelectors.getIcnReactBootstrap(state);

  return {
    user,
    isImposter,
    isWlp,
    icnReactBootstrap,
  };
}

export default withRouter(connect(mapStateToProps)(LayoutGlobal));
