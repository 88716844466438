import {
  FF_SET_REPRESENTATIVES_FOR_FILTER,
  FF_SET_FUND_OPTIONS_FOR_FILTER,
  FF_SET_FUNDS_FOR_FILTER,
  FF_SELECTED_FUNDS,
  FF_SELECTED_PERIOD,
  FF_CURRENT_PERIOD_TYPE,
  FF_SET_CURRENT_PAGE,
  FF_SET_RESULTS_PER_PAGE,
  FF_SELECTED_PERIOD_PICKER_TYPE,
  FF_SET_IS_LOADING_DEFAULT_VIEW,
  FF_SET_IS_LOADING_REP_DATA_FOR_FILTER,
  FF_SET_IS_LOADING_FUND_DATA_FOR_FILTER,
  FF_SET_IS_LOADING_FUNDS_FOR_FILTER,
} from 'actions/actionsConstants';

import update from 'immutability-helper';

import initialState from './initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case FF_SET_REPRESENTATIVES_FOR_FILTER:
      return update(state, {
        representatives_for_filter: { $set: action.payload },
      });
    case FF_SET_FUND_OPTIONS_FOR_FILTER:
      return update(state, {
        fund_options_for_filter: { $set: action.payload },
      });
    case FF_SET_FUNDS_FOR_FILTER:
      return update(state, {
        funds: { $set: action.payload },
      });
    case FF_SELECTED_FUNDS:
      return update(state, {
        selected_funds: { $set: action.payload },
      });
    case FF_SELECTED_PERIOD:
      return update(state, {
        selected_period: { $set: action.payload },
      });
    case FF_CURRENT_PERIOD_TYPE:
      return update(state, {
        current_period_type: { $set: action.payload },
      });
    case FF_SET_CURRENT_PAGE:
      return update(state, {
        current_page: { $set: action.payload },
      });
    case FF_SET_RESULTS_PER_PAGE:
      return update(state, {
        results_per_page: { $set: action.payload },
        current_page: { $set: 1 },
      });
    case FF_SELECTED_PERIOD_PICKER_TYPE:
      return update(state, {
        selected_period_picker_type: { $set: action.payload },
      });
    case FF_SET_IS_LOADING_DEFAULT_VIEW:
      return update(state, {
        is_loading_default_view: { $set: action.payload },
      });
    case FF_SET_IS_LOADING_REP_DATA_FOR_FILTER:
      return update(state, {
        is_loading_rep_data_for_filter: { $set: action.payload },
      });
    case FF_SET_IS_LOADING_FUND_DATA_FOR_FILTER:
      return update(state, {
        is_loading_fund_data_for_filter: { $set: action.payload },
      });
    case FF_SET_IS_LOADING_FUNDS_FOR_FILTER:
      return update(state, {
        is_loading_funds_for_filter: { $set: action.payload },
      });
    default:
      return state;
  }
};
