import axios from 'axios'

const integrationPartnersTemplateVersionsPath = 'flexible_feeds/dashboard/template_versions';

export const getIntegrationPartnerTemplateVersions = async ({ pageSize, pageIndex, filter }) => {
  const url = `${integrationPartnersTemplateVersionsPath}`;
  const params = { ...filter, per_page: pageSize, page: pageIndex };

  return axios.get(url, { params }, {
    withCredentials: true,
  }).then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};

export const getIntegrationPartnerTemplateVersion = async (versionId) => {
  const url = `${integrationPartnersTemplateVersionsPath}/${versionId}`;

  return axios.get(url, {
    withCredentials: true,
  }).then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
}

export const getIntegrationPartnerTemplateTags = () => {
  const url = `${integrationPartnersTemplateVersionsPath}/tags`;

  return axios.get(url, {
    withCredentials: true,
  }).then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};
