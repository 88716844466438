/* eslint-disable max-statements, max-lines */
import { createSelector } from 'reselect';
import camelCase from 'lodash/camelCase';
import { getIcnReactBootstrap } from 'selectors/icnBootstrapSelectors';
import calculateFlaggedFields from 'containers/subscription_review/tables/utils';
import isValidColumn from './isValidColumn';

export const selectedPafIdSelector = (state) => state.subscriptionReview.selected_paf_id;

export const selectedFundCategorySelector = (state) => {
  if (state.subscriptionReview.selected_fund_category) {
    return state.subscriptionReview.selected_fund_category;
  }
  const selectedPaf = state.subscriptionReview.pafs.find(
    (paf) => paf.private_access_fund.id === state.subscriptionReview.selected_paf_id
  );

  const pafSelectedFundCategory =
    selectedPaf &&
    state.subscriptionReview.fund_categories.find(
      (category) => category.value === selectedPaf.private_access_fund.category
    );

  return pafSelectedFundCategory?.value;
};

export const selectedPafInvestmentStatsByStatusSelector = (state) => {
  const stats = state.subscriptionReview.paf_investment_stats_by_status;

  let transfers = { investments_count: 0, commitment_amount: 0 };

  if (stats && stats.executed_transfers && stats.available_transfers) {
    transfers = {
      investments_count: stats.executed_transfers.transfers_count + stats.available_transfers.transfers_count,
      commitment_amount: stats.executed_transfers.transfers_amount + stats.available_transfers.transfers_amount,
    };
    if (stats.pending_review_transfers) {
      transfers.investments_count += stats.pending_review_transfers.transfers_count;
      transfers.commitment_amount += stats.pending_review_transfers.transfers_amount;
    }
  }

  const redemptions =
    stats && stats.available_redemptions && stats.holdback_redemptions && stats.completed_redemptions
      ? {
          investments_count:
            stats.available_redemptions.redemptions_count +
            stats.holdback_redemptions.redemptions_count +
            stats.completed_redemptions.redemptions_count,
          investments_with_canceled_count:
            stats.available_redemptions.redemptions_count +
            stats.holdback_redemptions.redemptions_count +
            stats.completed_redemptions.redemptions_count +
            stats.canceled_redemptions.redemptions_count,
          commitment_amount:
            stats.available_redemptions.redemptions_amount +
            stats.holdback_redemptions.redemptions_amount +
            stats.completed_redemptions.redemptions_amount,
        }
      : { investments_count: 0, commitment_amount: 0 };

  return {
    ...stats,
    transfers,
    redemptions,
  };
};

export const fundCategoriesSelector = (state) => state.subscriptionReview.fund_categories;

export const pafsSelector = (state) => state.subscriptionReview.pafs;

export const wlpsSelector = createSelector(
  (state) => state.subscriptionReview.wlps,
  (wlps) => wlps.map((w) => w.white_label_partner)
);

export const riasFirmsSelector = (state) => state.subscriptionReview.riasFirms;

export const generatedApprovalHistoryExportsSelector = (state) =>
  state.subscriptionReview.generatedApprovalHistoryExports;

export const generatedLeadApproverAssignmentsExportsSelector = (state) =>
  state.subscriptionReview.generatedLeadApproverAssignmentsExports;

export const generatedInvestmentsExportsSelector = (state) => state.subscriptionReview.generatedInvestmentsExports;

export const generatedRedemptionsExportsSelector = (state) => state.subscriptionReview.generatedRedemptionsExports;

export const generatedRemediationCommentsExportsSelector = (state) =>
  state.subscriptionReview.generatedRemediationCommentsExports;

export const generatedSignersExportsSelector = (state) => state.subscriptionReview.generatedSignersExports;

export const pafInvestmentGroupsSelector = (state) => state.subscriptionReview.paf_investment_groups;

export const investmentNotesSelector = (state) => state.subscriptionReview.investmentNotes;

export const closesForFundSelector = createSelector(
  (state) => state.subscriptionReview.closesForFund,
  (closesForFund) => closesForFund
);

export const subBooksForInvestmentSelector = (state) => state.subscriptionReview.subBooksForInvestment;

export const availableInvestmentShareClassesSelector = (state) =>
  state.subscriptionReview.availableInvestmentShareClasses;

export const selectedFiltersSelector = (state) => state.subscriptionReview.selected_filters;

export const searchTermSelector = (state) => state.subscriptionReview.searchTerm;

export const searchTypeSelector = (state) => state.subscriptionReview.searchType;

export const newCommentOnlyFilterSelector = (state) => state.subscriptionReview.selected_filters.new_comment_only;

export const gridStatesSelector = (state) => state.subscriptionReview.gridStates;

export const gridToggleStatesSelector = (state) => state.subscriptionReview.gridToggleStates;

export const stateFilterSelector = (state) => state.subscriptionReview.state_filters;

export const forceCloseDateFilterOptionsRefreshSelector = (state) =>
  state.subscriptionReview.forceCloseDateFilterOptionsRefresh;

export const currentSavedViewSelector = (state) => state.subscriptionReview.currentSavedView;

export const isSavedViewIncompleteSelector = (state) => state.subscriptionReview.isSavedViewIncomplete;

export const groupedInvestmentsSelector = createSelector(
  [getIcnReactBootstrap, pafInvestmentGroupsSelector],
  (icnBootstrap, pafInvestmentGroups) =>
    Object.keys(pafInvestmentGroups).reduce((memo, curr) => {
      memo[camelCase(curr)] = pafInvestmentGroups[curr].map((investment) => ({
        ...investment,
        flagged_fields: calculateFlaggedFields(investment, icnBootstrap),
      }));
      return memo;
    }, {})
);

export const snackbarSelector = (state) => state.subscriptionReview.snackbar;

export const pafDetailSelector = (state) => state.subscriptionReview.selectedPaf;

export const isPafFundingForCloseSelector = (state) =>
  state.subscriptionReview.selectedPaf?.private_access_fund?.funding_for_close;

export const pafCurrencySelector = createSelector(
  [pafDetailSelector],
  (selectedPaf) => selectedPaf?.private_access_fund?.fund_currency?.iso_code
);

export const pafGpLegalEntityName = (paf) => (paf ? paf.private_access_fund.gp_legal_entity_name : '');

export const pafGpLegalEntityNameSelector = createSelector([pafDetailSelector], pafGpLegalEntityName);

export const pafName = (paf) => (paf ? paf.private_access_fund.name : '');

export const pafNameSelector = createSelector([pafDetailSelector], pafName);

export const userViewSelector = (state) => state.subscriptionReview.user_views;

export const pafSummarySelector = (state) => state.subscriptionReview.paf_summary;

export const pafHasCanceledInvestmentsSelector = (state) => state.subscriptionReview.paf_has_canceled_investments;

export const pafNextCloseSelector = (state) => state.subscriptionReview.paf_next_close;

export const pafAggregationsSelector = (state) => state.subscriptionReview.paf_aggregations;

export const userViewStatuesSelector = createSelector([pafSummarySelector, userViewSelector], (pafSummary, userView) =>
  userView ? userView.statuses : pafSummary.allowed_statuses
);

export const allowedStatusesForCustomizeView = createSelector([pafSummarySelector], (pafSummary) =>
  pafSummary.allowed_statuses.filter((item) => item !== 'reverted')
);

export const customizeViewModalResponsesSelector = createSelector(
  [allowedStatusesForCustomizeView, selectedPafInvestmentStatsByStatusSelector],
  (statuses, investmentStats) => {
    const responses = statuses.map((key) => ({
      key,
      count: investmentStats[key].investments_count,
    }));
    return responses;
  }
);

export const selectedUserViewsSelector = createSelector(
  [pafSummarySelector, userViewStatuesSelector],
  (pafSummary, userView) => {
    const selectedOptions = {};
    pafSummary.allowed_statuses.forEach((key) => {
      if (key === 'reverted') {
        return;
      }
      selectedOptions[key] = userView.includes(key);
    });

    if (Object.keys(selectedOptions).length === userView.length) {
      selectedOptions.all = true;
    }
    return selectedOptions;
  }
);

export const groupedPafsByCategory = (pafs = []) =>
  pafs.reduce((memo, curr) => {
    const category = curr.private_access_fund.category;

    if (!memo[category]) {
      memo[category] = [];
    }
    memo[category].push(curr);
    return memo;
  }, {});

const formattedPafGroups = (groupedPafs) =>
  Object.keys(groupedPafs).map((group) => {
    const funds = groupedPafs[group];
    return {
      label: group,
      options: funds.map((fund) => ({
        label: fund.private_access_fund.name,
        value: fund.private_access_fund.id,
      })),
    };
  });

export const formattedPafGroupsSelector = createSelector(
  [pafsSelector, selectedFundCategorySelector],
  (pafs, selectedFundCategory) => {
    const groupedPafs =
      selectedFundCategory && selectedFundCategory !== 'All Funds'
        ? { [selectedFundCategory]: groupedPafsByCategory(pafs)[selectedFundCategory] }
        : groupedPafsByCategory(pafs);
    return formattedPafGroups(groupedPafs);
  }
);

const formattedFundCategories = (fundCategories) =>
  fundCategories.map((fundType) => ({
    label: fundType.name,
    value: fundType.value,
  }));

export const formattedFundCategoriesSelector = createSelector([fundCategoriesSelector], formattedFundCategories);

export const fundTypes = (pafList = []) => {
  const fundTypeData = [{ name: 'All Funds', value: 'All Funds' }];
  Object.keys(groupedPafsByCategory(pafList)).forEach((k) => {
    fundTypeData.push({
      name: k,
      value: k,
    });
  });
  return fundTypeData;
};

const filterTransfers = (list) => list.filter((inv) => inv.transfer_date === null && inv.type !== 'Transfer');

export const investmentCount = (list) => filterTransfers(list).length;

export const totalAmount = (list, includeTransfers = false) =>
  (includeTransfers ? list : filterTransfers(list))
    .reduce((memo, curr) => memo + curr.amount, 0)
    .toLocaleString({
      style: 'currency',
      currency: 'USD',
    });

// if there is no state for the column return false, otherwise return the saved value
export const isColumnHidden = (colId, state) => (!state?.[colId] ? false : state[colId]);

export const getColumns = ({
  user,
  colDefs,
  investmentStatus,
  selectedPaf,
  whiteLabelPartner,
  gridState,
  showCheckbox,
  showColumns,
}) => {
  let columns = colDefs.filter((col) =>
    isValidColumn({
      user,
      columnName: col.field || col.colId,
      investmentStatus,
      selectedPaf,
      whiteLabelPartner,
      showCheckbox,
      showColumns,
    })
  );

  if (!gridState) return columns;

  columns = columns.map((c) => ({
    ...c,
    hide: isColumnHidden(c.field || c.colId, gridState),
  }));

  const columnOrders = gridState.column_order_position;
  if (columnOrders) {
    return columns.sort((a, b) => {
      const aId = a.field || a.colId;
      const bId = b.field || b.colId;

      const aIndex = columnOrders.indexOf(aId);
      const bIndex = columnOrders.indexOf(bId);

      if (aIndex === -1) return bIndex;
      if (bIndex === -1) return aIndex;

      return aIndex - bIndex;
    });
  }
  return columns;
};

export const hasChangedInvestments = (prevInvestments, currInvestments) => {
  if (prevInvestments.length !== currInvestments.length) {
    return true;
  }

  return currInvestments.some((currInv) => {
    const prevInv = prevInvestments.find((inv) => inv.id === currInv.id);
    if (!prevInv) return true;

    // compare key size on both investments
    if (Object.keys(currInv).length !== Object.keys(prevInv).length) {
      return true;
    }

    // checks for missing or changed properties
    return Object.keys(currInv).some((key) => prevInv[key] === undefined || currInv[key] !== prevInv[key]);
  });
};

export const reversionContactCardId = (investment) =>
  investment.ria_email ? investment.ria_contact_card_id : investment.investor_contact_card_id;

export const reversionNameAndEmail = (investment) => {
  let name;
  let email;
  if (investment.ria_email) {
    name = investment.ria_user;
    email = investment.ria_email;
  } else {
    name = investment.investor_name;
    email = investment.investor_email;
  }
  return { name, email };
};

const delegateMessage = (blacklisted, delegateBlacklisted, investment) => {
  if (!blacklisted) {
    return delegateBlacklisted
      ? 'The delegate(s) have elected not to receive remediation notifications. ' +
          'If you wish to override this preference, the notification will be sent to:'
      : 'Send Remediation Notification to:';
  }

  const { name, email } = reversionNameAndEmail(investment);
  const nameAndEmail = `${name} (${email})`;
  const msg = delegateBlacklisted ? `${nameAndEmail} and the delegate(s) have` : `${nameAndEmail} has`;
  return (
    `${msg} elected not to receive remediation notifications.` +
    ' If you wish to override this preference, the notification will be sent to:'
  );
};

const representativeMessage = (blacklisted, representativeBlacklisted, investment) => {
  if (!blacklisted) {
    return representativeBlacklisted
      ? 'The representative(s) have elected not to receive remediation notifications. ' +
          'If you wish to override this preference, the notification will be sent to:'
      : 'Send Remediation Notification to:';
  }

  const { name, email } = reversionNameAndEmail(investment);
  const nameAndEmail = `${name} (${email})`;
  const msg = representativeBlacklisted ? `${nameAndEmail} and the representative(s) have` : `${nameAndEmail} has`;
  return (
    `${msg} elected not to receive remediation notifications.` +
    ' If you wish to override this preference, the notification will be sent to:'
  );
};

const remediationMessage = (blacklisted, investment) => {
  const { name, email } = reversionNameAndEmail(investment);
  const nameAndEmail = `${name} (${email})`;
  if (blacklisted) {
    return (
      `${nameAndEmail} has elected not to receive` +
      ' remediation notifications. Do you wish to override this preference?'
    );
  }
  return `Send Remediation Notification to ${nameAndEmail}?`;
};

export const isInvestorWithRepresentatives = (investment) =>
  Boolean(['QP', 'INVESTOR'].includes(investment?.investor_type) && investment?.has_representatives);

export const reversionEmailAndMessage = (investment, data) => {
  let message;
  const { email } = reversionNameAndEmail(investment);

  if (data.qp_with_delegates) {
    message = delegateMessage(data.blacklisted, data.is_delegate_blacklisted, investment);
  } else if (data.qp_with_representatives) {
    message = representativeMessage(data.blacklisted, data.is_representative_blacklisted, investment);
  } else {
    message = remediationMessage(data.blacklisted, investment);
  }

  return { email, message };
};

export const selectedIdsSelector = (state) => state.subscriptionReview.investmentList.selectedIds;

export const currentBucketSelector = (state) => state.subscriptionReview.investmentList.currentBucket;

export const defaultSelectedColumns = [
  'ria_user',
  'custodian',
  'doc_delivery_eligibility',
  'doc_delivery_status',
  'order_delivery_status',
  'custodian_forms_received',
  'general_partner_signature',
  'profile_matched',
  'admin_documents_sent_at',
  'custodian_account_name',
  'new_issues_participant',
  'erisa',
  'sign_method',
  'recent_aml_upload',
  'delegate_name',
];
