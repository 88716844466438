/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import {
  ID_SET_ADVISORS,
  ID_SELECT_ADVISOR,
  ID_SET_INVESTORS,
  ID_SELECT_INVESTOR,
  ID_SET_ACCOUNTS,
  ID_SELECT_ACCOUNTS,
  ID_RESET_ADVISOR,
  ID_RESET_INVESTOR,
  ID_RESET_ACCOUNT,
  ID_SET_FUNDS,
  ID_SELECT_FUND,
  ID_SET_FUND_DATA,
  ID_RESET_FUND_DATA,
  ID_SET_INVESTMENT_DATA,
  ID_RESET_INVESTMENT_DATA,
  ID_SET_GENERAL_LEDGER_TRANSACTIONS_DATA,
  ID_SET_ALL_GENERAL_LEDGER_TRANSACTIONS_DATA,
  ID_SELECT_ACTIVE_TAB,
  ID_UPDATE_DATA_SETS_LOADED,
  ID_SET_FUND_DATA_METRICS,
  ID_FETCHING_ADVISORS_ON_GOING,
  ID_FETCHING_INVESTORS_ON_GOING,
  ID_FETCHING_ACCOUNTS_ON_GOING,
  ID_FETCHING_FUNDS_ON_GOING,
  ID_SET_INVESTOR_PROFILE_QUERY_STRING,
  ID_FETCHING_INVESTOR_PROFILE_QUERY_STRING_ON_GOING,
  ID_SET_DOC_CENTER_WIDGET_CACHE,
  ID_SET_EXPORTS,
  ID_SET_EXPORT_FLYOVER_STATUS,
  ID_SET_EXPORT_CSV_DATA,
  ID_SET_HIDE_ADVISOR_DROPDOWN,
  ID_SET_AGGREGATION_LEVEL,
  ID_SET_DISCLAIMERS,
  ID_SET_CONFIG,
  ID_SET_SELECTED_ASSET_CLASS,
  ID_SET_TABS,
  ID_SET_ASSET_CLASSES,
  ID_SET_FILTERED_ACCOUNTS,
  ID_RESET_FUND,
  ID_RESET_ASSET_CLASS,
  ID_SET_SELECTED_CURRENCY,
} from 'actions/actionsConstants';

import { prependPartnerProxyAPIPrefix } from 'services/axios/setBaseUrl';

import update from 'immutability-helper';

/**
 * @typedef {import('prop_types/investment_dashboard/investment_dashboard_prop_types').Advisor} Advisor
 * @typedef {import('prop_types/investment_dashboard/investment_dashboard_prop_types').Investor} Investor
 * @typedef {import('prop_types/investment_dashboard/investment_dashboard_prop_types').Accounts} Accounts
 * @typedef {import('prop_types/investment_dashboard/investment_dashboard_prop_types').Fund} Fund
 * @typedef {import('prop_types/investment_dashboard/investment_dashboard_prop_types').CapitalCallOutstanding} CapitalCallOutstanding
 * @typedef {import('prop_types/investment_dashboard/investment_dashboard_prop_types').Transaction} Transaction
 * @typedef {import('prop_types/investment_dashboard/investment_dashboard_prop_types').Tab} Tab
 * @typedef {import('prop_types/investment_dashboard/investment_dashboard_prop_types').DataSetLoaded} DataSetLoaded
 * @typedef {import('prop_types/investment_dashboard/disclaimers').Disclaimer} Disclaimer
 * @typedef {import('prop_types/investment_dashboard/configs').Config} Config
 */

/**
 * @typedef {Object} InitialState
 * @property {Advisor[]} advisors - An array of advisors
 * @property {Advisor} selectedAdvisor - The selected advisor
 * @property {Investor[]} investors - An array of investors
 * @property {Investor} selectedInvestor - The selected investor
 * @property {Accounts[]} accounts - An array of accounts
 * @property {Accounts} selectedAccounts - The selected accounts
 * @property {Fund[]} funds - An array of funds
 * @property {Fund} selectedFund - The selected fund
 * @property {CapitalCallOutstanding[]} capitalCallOutstanding - An array of outstanding capital calls
 * @property {string} quarterlyReportUrl - The URL of the quarterly report
 * @property {number[] | null} investmentsIds - An array of investment IDs
 * @property {number[] | null} externalCommitmentsIds - An array of external commitment IDs
 * @property {boolean} detailedPcapReport - A flag indicating if the PCAP report is detailed
 * @property {boolean} inclusiveFees - A flag indicating if the fees are inclusive
 * @property {Object} verifiedGeneralLedgerTransactions - An object of verified general ledger transactions
 * @property {Object} subsequentGeneralLedgerTransactions - An object of subsequent general ledger transactions
 * @property {string} aggregationLevel - The aggregation level
 * @property {Transaction} transactions - The transactions object
 * @property {string} investorProfileQueryString - The query string for the investor profile
 * @property {Tab} activeTab - The active tab
 * @property {DataSetLoaded} dataSetsLoaded - An object of data sets loaded
 * @property {Object} fundDataMetrics - An object of fund data metrics
 * @property {boolean} fetchingAdvisorsOnGoing - A flag indicating if the advisors are being fetched
 * @property {boolean} fetchingInvestorsOnGoing - A flag indicating if the investors are being fetched
 * @property {boolean} fetchingInvestorProfileQueryStringOnGoing - A flag if the IPQ is being fetched
 * @property {boolean} fetchingAccountsOnGoing - A flag indicating if the accounts are being fetched
 * @property {boolean} fetchingFundsOnGoing - A flag indicating if the funds are being fetched
 * @property {Object} exportHistory - The export history object
 * @property {Object} docCenterWidgetCache - The doc center widget cache object
 * @property {boolean} hideAdvisorDropdown - A flag indicating if the advisor dropdown is hidden
 * @property {Object} fundCurrencyData - The fund currency data object
 * @property {Object} selectedCurrencyData - The selected currency data object
 * @property {string} quarterlyReportUrl - The URL of the quarterly report
 * @property {Disclaimer[]} disclaimers - An array of disclaimers
 * @property {Config} config - The config object
 * @property {string} selectedAssetClass - The selected asset class
 * @property {Tab[]} tabs - An array of tabs
 * @property {string[]} assetClasses - An array of asset classes
 * @property {Accounts[] | null} filteredAccounts - An array of filtered accounts
 * @property {string} [fundCurrencyCode] - The fund currency code
 */

/**
 * @type {InitialState}
 */
export const initialState = {
  advisors: [],
  selectedAdvisor: null,
  investors: [],
  selectedInvestor: null,
  accounts: [],
  selectedAccounts: null,
  funds: [],
  selectedFund: null,
  capitalCallOutstanding: [],
  quarterlyReportUrl: null,
  investmentsIds: undefined,
  externalCommitmentsIds: undefined,
  detailedPcapReport: false,
  inclusiveFees: false,
  verifiedGeneralLedgerTransactions: {},
  subsequentGeneralLedgerTransactions: {},
  aggregationLevel: null,
  transactions: {
    transactions_array: [],
    transactions_count: 0,
    transactions_periods: [],
    transactions_categories: [],
  },
  investorProfileQueryString: '',
  activeTab: { id: 0, key: 'investment_overview', label: 'overview' },
  dataSetsLoaded: {
    investmentsDataSet: false,
    exportHistoryDataSet: false,
    transactionsDataSet: false,
    fundDataMetricsDataSet: false,
    docCenterWidgetCacheDataSet: false,
  },
  fundDataMetrics: undefined,
  fetchingAdvisorsOnGoing: false,
  fetchingInvestorsOnGoing: false,
  fetchingInvestorProfileQueryStringOnGoing: false,
  fetchingAccountsOnGoing: false,
  fetchingFundsOnGoing: false,
  exportHistory: {
    exports: [],
    exportFlyoverStatus: undefined,
    csvData: undefined,
  },
  docCenterWidgetCache: {
    documents: [],
    totalCount: 0,
    currentPage: 1,
    perPage: 25,
    sortField: 'publish_date',
    sortDirection: 'desc',
  },
  hideAdvisorDropdown: false,
  fundCurrencyData: {
    currencyCode: '',
    allStatements: undefined,
    statementsByAccountByFund: undefined,
    statementsByAccount: undefined,
    statementsByFund: undefined,
  },
  selectedCurrencyData: {
    currencyCode: 'USD',
    allStatements: undefined,
    statementsByAccountByFund: undefined,
    statementsByAccount: undefined,
    statementsByFund: undefined,
    totalCommitment: undefined,
    // temporary field while investments net irr is not integrated in statements
    netIrr: undefined,
  },
  disclaimers: [],
  config: { layout: 'default', style: 'default' },
  selectedAssetClass: null,
  tabs: [
    { id: 0, key: 'investment_overview', label: 'overview' },
    { id: 1, key: 'cash_flow', label: 'cash_flow' },
    { id: 2, key: 'document_center', label: 'document_other' },
  ],
  assetClasses: null,
  filteredAccounts: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case ID_SET_ADVISORS: {
      return update(state, {
        advisors: { $set: action.payload },
      });
    }
    case ID_SELECT_ADVISOR: {
      return update(state, {
        selectedAdvisor: { $set: action.payload },
      });
    }
    case ID_SET_INVESTORS: {
      return update(state, {
        investors: { $set: action.payload },
      });
    }
    case ID_SELECT_INVESTOR: {
      return update(state, {
        selectedInvestor: { $set: action.payload },
      });
    }
    case ID_SET_ACCOUNTS: {
      return update(state, {
        accounts: { $set: action.payload },
      });
    }
    case ID_SELECT_ACCOUNTS: {
      return update(state, {
        selectedAccounts: { $set: action.payload },
      });
    }
    case ID_RESET_ADVISOR: {
      return update(state, {
        investors: { $set: initialState.investors },
        selectedInvestor: { $set: initialState.selectedInvestor },
        accounts: { $set: initialState.accounts },
        selectedAccounts: { $set: initialState.selectedAccounts },
        funds: { $set: initialState.funds },
        selectedFund: { $set: initialState.selectedFund },
        assetClasses: { $set: initialState.assetClasses },
        selectedAssetClass: { $set: initialState.selectedAssetClass },
        filteredAccounts: { $set: initialState.filteredAccounts },
        aggregationLevel: { $set: initialState.aggregationLevel },
      });
    }
    case ID_RESET_INVESTOR: {
      return update(state, {
        accounts: { $set: initialState.accounts },
        selectedAccounts: { $set: initialState.selectedAccounts },
        funds: { $set: initialState.funds },
        selectedFund: { $set: initialState.selectedFund },
        assetClasses: { $set: initialState.assetClasses },
        selectedAssetClass: { $set: initialState.selectedAssetClass },
        filteredAccounts: { $set: initialState.filteredAccounts },
        aggregationLevel: { $set: initialState.aggregationLevel },
      });
    }
    case ID_RESET_ACCOUNT: {
      return update(state, {
        funds: { $set: initialState.funds },
        selectedFund: { $set: initialState.selectedFund },
        filteredAccounts: { $set: initialState.filteredAccounts },
        aggregationLevel: { $set: initialState.aggregationLevel },
      });
    }
    case ID_RESET_FUND: {
      return update(state, {
        filteredAccounts: { $set: initialState.filteredAccounts },
        aggregationLevel: { $set: initialState.aggregationLevel },
        selectedFund: { $set: initialState.selectedFund },
      });
    }
    case ID_RESET_ASSET_CLASS: {
      return update(state, {
        filteredAccounts: { $set: initialState.filteredAccounts },
        aggregationLevel: { $set: initialState.aggregationLevel },
        selectedFund: { $set: initialState.selectedFund },
      });
    }
    case ID_SET_FUNDS: {
      return update(state, {
        funds: { $set: action.payload },
      });
    }
    case ID_SELECT_FUND: {
      const selectedFund = action.payload;
      return update(state, {
        selectedFund: { $set: selectedFund },
      });
    }
    case ID_SET_FUND_DATA: {
      return update(state, {
        detailedPcapReport: { $set: action.payload.detailed_pcap_report },
        capitalCallOutstanding: { $set: action.payload.outstanding_capital_calls },
        quarterlyReportUrl: {
          $set: prependPartnerProxyAPIPrefix(action.payload.quarterly_report_url),
        },
        investmentsIds: { $set: action.payload.investments_ids },
        externalCommitmentsIds: { $set: action.payload.external_commitments_ids },
        inclusiveFees: { $set: action.payload.inclusive_fees },
        fundCurrencyData: { $merge: { currencyCode: action.payload.fund_currency_code } },
      });
    }
    case ID_RESET_FUND_DATA: {
      return update(state, {
        detailedPcapReport: { $set: initialState.detailedPcapReport },
        capitalCallOutstanding: { $set: initialState.capitalCallOutstanding },
        quarterlyReportUrl: { $set: initialState.quarterlyReportUrl },
        investmentsIds: { $set: initialState.investmentsIds },
        externalCommitmentsIds: { $set: initialState.externalCommitmentsIds },
        inclusiveFees: { $set: initialState.inclusiveFees },
        fundCurrencyCode: { $set: initialState.fundCurrencyCode },
        transactions: { $set: initialState.transactions },
        fundDataMetrics: { $set: initialState.fundDataMetrics },
        dataSetsLoaded: { $set: initialState.dataSetsLoaded },
        disclaimers: { $set: initialState.disclaimers },
      });
    }
    case ID_SET_INVESTMENT_DATA: {
      return update(state, {
        fundCurrencyData: {
          $merge: {
            allStatements: action.payload.investmentInFundCurrency?.all_pcaps,
            statementsByFund: action.payload.investmentInFundCurrency?.pcaps_by_fund,
            statementsByAccountByFund: action.payload.investmentInFundCurrency?.pcaps_by_account_by_fund,
            statementsByAccount: action.payload.investmentInFundCurrency?.pcaps_by_account,
            netIrr: action.payload.investmentInFundCurrency?.net_irr,
          },
        },
        selectedCurrencyData: {
          $merge: {
            allStatements: action.payload.investmentInSelectedCurrency?.all_pcaps,
            statementsByFund: action.payload.investmentInSelectedCurrency?.pcaps_by_fund,
            statementsByAccountByFund: action.payload.investmentInSelectedCurrency?.pcaps_by_account_by_fund,
            statementsByAccount: action.payload.investmentInSelectedCurrency?.pcaps_by_account,
            netIrr: action.payload.investmentInSelectedCurrency?.net_irr,
            totalCommitment: action.payload.investmentInSelectedCurrency?.total_commitment,
          },
        },
      });
    }
    case ID_SET_GENERAL_LEDGER_TRANSACTIONS_DATA: {
      return update(state, {
        verifiedGeneralLedgerTransactions: { $set: action.payload.verified_general_ledger_transactions },
        subsequentGeneralLedgerTransactions: { $set: action.payload.subsequent_general_ledger_transactions },
      });
    }
    case ID_RESET_INVESTMENT_DATA: {
      return update(state, {
        fundCurrencyData: {
          $merge: {
            allStatements: initialState.fundCurrencyData.allStatements,
            statementsByFund: initialState.fundCurrencyData.statementsByFund,
            statementsByAccountByFund: initialState.fundCurrencyData.statementsByAccountByFund,
            statementsByAccount: initialState.fundCurrencyData.statementsByAccount,
            netIrr: initialState.fundCurrencyData.netIrr,
          },
        },
        selectedCurrencyData: {
          $merge: {
            allStatements: initialState.selectedCurrencyData.allStatements,
            statementsByFund: initialState.selectedCurrencyData.statementsByFund,
            statementsByAccountByFund: initialState.selectedCurrencyData.statementsByAccountByFund,
            statementsByAccount: initialState.selectedCurrencyData.statementsByAccount,
            netIrr: initialState.selectedCurrencyData.netIrr,
            totalCommitment: initialState.selectedCurrencyData.totalCommitment,
          },
        },
        verifiedGeneralLedgerTransactions: { $set: initialState.verifiedGeneralLedgerTransactions },
        subsequentGeneralLedgerTransactions: {
          $set: initialState.subsequentGeneralLedgerTransactions,
        },
        transactions: { $set: initialState.transactions },
        dataSetsLoaded: {
          $merge: {
            investmentsDataSet: initialState.dataSetsLoaded.investmentsDataSet,
            exportHistoryDataSet: initialState.dataSetsLoaded.exportHistoryDataSet,
            transactionsDataSet: initialState.dataSetsLoaded.transactionsDataSet,
          },
        },
      });
    }
    case ID_SET_ALL_GENERAL_LEDGER_TRANSACTIONS_DATA: {
      return update(state, {
        transactions: { $set: action.payload },
      });
    }
    case ID_SET_INVESTOR_PROFILE_QUERY_STRING: {
      return update(state, {
        investorProfileQueryString: { $set: action.payload },
      });
    }
    case ID_SELECT_ACTIVE_TAB: {
      return update(state, {
        activeTab: { $set: action.payload },
      });
    }
    case ID_UPDATE_DATA_SETS_LOADED: {
      return update(state, {
        dataSetsLoaded: { $merge: action.payload },
      });
    }
    case ID_SET_FUND_DATA_METRICS: {
      return update(state, {
        fundDataMetrics: { $set: action.payload },
      });
    }
    case ID_FETCHING_ADVISORS_ON_GOING: {
      return update(state, {
        fetchingAdvisorsOnGoing: { $set: action.payload },
      });
    }
    case ID_FETCHING_INVESTORS_ON_GOING: {
      return update(state, {
        fetchingInvestorsOnGoing: { $set: action.payload },
      });
    }
    case ID_FETCHING_INVESTOR_PROFILE_QUERY_STRING_ON_GOING: {
      return update(state, {
        fetchingInvestorProfileQueryStringOnGoing: { $set: action.payload },
      });
    }
    case ID_FETCHING_ACCOUNTS_ON_GOING: {
      return update(state, {
        fetchingAccountsOnGoing: { $set: action.payload },
      });
    }
    case ID_FETCHING_FUNDS_ON_GOING: {
      return update(state, {
        fetchingFundsOnGoing: { $set: action.payload },
      });
    }
    case ID_SET_DOC_CENTER_WIDGET_CACHE: {
      return update(state, {
        docCenterWidgetCache: { $set: action.payload },
      });
    }
    case ID_SET_EXPORTS: {
      return update(state, {
        exportHistory: { $merge: { exports: action.payload.exports } },
      });
    }
    case ID_SET_EXPORT_FLYOVER_STATUS: {
      return update(state, {
        exportHistory: { $merge: { exportFlyoverStatus: action.payload } },
      });
    }
    case ID_SET_EXPORT_CSV_DATA: {
      return update(state, {
        exportHistory: { $merge: { csvData: action.payload } },
      });
    }
    case ID_SET_HIDE_ADVISOR_DROPDOWN: {
      return update(state, {
        hideAdvisorDropdown: { $set: action.payload },
      });
    }
    case ID_SET_AGGREGATION_LEVEL: {
      return update(state, {
        aggregationLevel: { $set: action.payload },
      });
    }
    case ID_SET_DISCLAIMERS: {
      return update(state, {
        disclaimers: { $set: action.payload },
      });
    }
    case ID_SET_CONFIG: {
      return update(state, {
        config: { $set: action.payload },
      });
    }
    case ID_SET_SELECTED_ASSET_CLASS: {
      return update(state, {
        selectedAssetClass: { $set: action.payload },
      });
    }
    case ID_SET_TABS: {
      return update(state, {
        tabs: { $set: action.payload },
      });
    }
    case ID_SET_ASSET_CLASSES: {
      return update(state, {
        assetClasses: { $set: action.payload },
      });
    }
    case ID_SET_FILTERED_ACCOUNTS: {
      return update(state, {
        filteredAccounts: { $set: action.payload },
      });
    }
    case ID_SET_SELECTED_CURRENCY: {
      return update(state, {
        selectedCurrencyData: {
          $merge: {
            currencyCode: action.payload,
          },
        },
      });
    }
    default:
      return state;
  }
};
