import React, { useState } from 'react';
import { Snackbar } from '@icapitalnetwork/supernova-core';
import actions from 'actions/access_agreements_actions/accessAgreementsActions';
import { requestFailed } from 'constants/access_agreements';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { func } from 'prop-types';

function AccessAgreementsSnackbar({ setRequestFailed }) {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    setRequestFailed(false);
  };

  return (
    <Snackbar
      open={isOpen}
      onClose={handleClose}
      message={requestFailed}
      severity="error"
      action={null}
      autoHideDuration={5000}
    />
  );
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setRequestFailed: actions.aaSetRequestFailed,
    },
    dispatch
  );
}

AccessAgreementsSnackbar.propTypes = {
  setRequestFailed: func.isRequired,
};

export default connect(null, mapDispatchToProps)(AccessAgreementsSnackbar);
