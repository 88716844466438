/* eslint-disable max-lines */
import axios from 'axios';

export const allWlpObj = { value: -1, label: 'All White Labels', all: true };
export const flagshipWlp = { value: 0, label: 'Flagship' };
export const allFirmsObj = { value: 0, label: 'All Firms', all: true };

// Sort array alfabetically, but keeps all obj at the beginning
export const sortKeepAllObjBeginning = (a, b) => {
  if (a.label.substring(0, 4) === 'All ') return -1;
  if (b.label.substring(0, 4) === 'All ') return 1;
  return a.label.localeCompare(b.label, 'en', { sensitivity: 'base' });
};

// Removes duplicates from arr by the value key
// Keeps last object for given value
// Creates new array in the process
export const removeDuplicates = (arr) => {
  const obj = {};
  const ret = [];

  for (let i = 0; i < arr.length; i++) {
    obj[arr[i].value] = arr[i].label;
  }

  for (const key in obj) {
    ret.push({
      value: parseInt(key, 10),
      label: obj[key],
    });
  }

  return ret;
};

// Create FundDocument Object from API info
export const createDocument = (payload, status = 0) => ({
  id: payload.id,
  fileName: payload.file_name,
  fileSize: payload.file_size,
  companionFileName: payload.companion_file,
  fund: payload.fund,
  whiteLabelPartner: payload.white_label_partner,
  firm: payload.firm,
  selectedExcWlps: payload.excluded_white_label_partners,
  selectedExcFirms: payload.excluded_firms,
  availableBranchCodes: payload.available_branch_codes,
  selectedBranchCodes: payload.selected_branch_codes,
  date: payload.uploaded_at,
  expirationDate: payload.expiration_date,
  documentType: payload.document_type,
  url: payload.url,
  position: payload.position,
  offeringMaterial: payload.offering_material,
  status,
  notifyPublication: payload.notify_publication,
  userCanApprove: payload.user_can_approve,
  approved: payload.approved,
  displayName: payload.display_name,
  newFile: false,
  previewEmails: payload.preview_emails,
  previewStatus: payload.preview_status,
  previewEventUuid: payload.preview_event_uuid,
  restrictDownloads: payload.restrict_downloads,
  hideFromDocCenter: payload.hide_from_doc_center,
  internal: payload.internal,
  uploader: payload.uploader,
  documentApprovalState: payload.document_approval_state,
  onePerFund: payload.one_per_fund,
  formSchema: payload.document_manager_form,
  formResponses: payload.document_manager_form_responses,
  companionFile: payload.companion_file,
  allowCompanionFile: payload.allow_companion_file,
  documentTab: 'fund_profile',
});

export const createLibraryDocument = (payload, position, status = 0) => ({
  id: payload.id,
  fileName: payload.file_name,
  fileSize: payload.file_size,
  fund: payload.fund,
  whiteLabelPartner: payload.white_label_partner,
  date: payload.uploaded_at,
  effectiveDate: payload.effective_date,
  expirationDate: payload.expiration_date,
  documentType: payload.document_type,
  url: payload.url,
  position,
  status,
  displayName: payload.display_name,
  userCanApprove: payload.user_can_approve,
  approved: payload.approved,
  newFile: false,
  uploader: payload.uploader,
  onePerFund: payload.one_per_fund,
  formSchema: payload.document_manager_form,
  formResponses: payload.document_manager_form_responses,
  documentTab: 'doc_library',
});

export const createFundDocumentTemplate = (payload, position) => ({
  id: payload.id,
  displayName: payload.display_name,
  createdBy: payload.created_by,
  updatedBy: payload.updated_by,
  dateCreated: payload.date_created,
  lastUpdated: payload.last_updated,
  documentType: payload.document_type,
  fund: payload.fund,
  orientation: payload.orientation,
  whiteLabelPartners: payload.white_label_partners,
  position,
  templateStatus: payload.template_status,
  url: payload.url,
  fileName: payload.file_name,
  fileSize: payload.file_size,
  date: payload.date,
  label: payload.label,
  value: payload.value,
  documentTab: 'templates',
});

export const getByValue = (selectedId, collection) => (
  collection.find((el) => el.value === selectedId)
);

export const getByValues = (selectedIds, collection) => {
  collection.filter(el => !selectedIds.includes(el.value))
};

export const getDocumentType = (label, docTypes) => {
  const option = docTypes.find((el) => el.label === label);

  if (!option) {
    return { value: label, label };
  }

  return option;
};

export const multiValues = (values, collection) => values.map((e) => ({ value: e.id, label: e.name }));

export const initialFileInfo = (props) => {
  if (props.selectedDocument) {
    let firm = null
    if (props.selectedDocument.firm) {
      firm = { value: props.selectedDocument.firm.id, label: props.selectedDocument.firm.name }
    }
    const storedFunds = JSON.parse(localStorage.getItem('recentFunds')) || [];
    const listFundsWithStored = props.funds.concat(storedFunds);
    return {
      selectedFunds: [getByValue(props.selectedDocument.fund.id, listFundsWithStored)],
      selectedDocType: getDocumentType(props.selectedDocument.documentType, props.documentTypes),
      selectedWlp: getByValue(props.selectedDocument.whiteLabelPartner.id, props.wlps),
      selectedFirm: getByValue(props.selectedDocument.firm.id, props.firms) || firm,
      selectedExcWlps: multiValues(props.selectedDocument.selectedExcWlps || [], props.wlps),
      selectedExcFirms: multiValues(props.selectedDocument.selectedExcFirms || [], props.firms),
      selectedBranchCodes: props.selectedDocument.selectedBranchCodes || [],
      availableBranchCodes: props.selectedDocument.availableBranchCodes || [],
      selectedDate: new Date(props.selectedDocument.date),
      offeringMaterial: props.selectedDocument.offeringMaterial,
      notifyPublication: props.selectedDocument.notifyPublication,
      displayName: props.selectedDocument.displayName,
      newFile: false,
      libraryDocId: null,
      expirationDate: props.selectedDocument.expirationDate,
      previewEmails: props.selectedDocument.previewEmails,
      restrictDownloads: props.selectedDocument.restrictDownloads,
      hideFromDocCenter: props.selectedDocument.hideFromDocCenter,
      internal: props.selectedDocument.internal,
      formSchema: props.selectedDocument.formSchema,
      formResponses: props.selectedDocument.formResponses,
      allowCompanionFile: false,
      selectedTemplate: props.selectedDocument.selectedTemplate,
      wrapDocument: props.selectedDocument.wrapDocument,
    };
  }

  const selectedWlp = props.selectedWhiteLabelPartner || props.wlps[0];

  return {
    selectedDocType: null,
    selectedFunds: props.fundFilterSelected ? [props.fundFilterSelected] : [],
    selectedWlp: selectedWlp,
    selectedFirm: props.selectedFirm || props.firms[0],
    selectedExcWlps: props.selectedExcWlps || props.wlps.filter(e => e.autoRestrict && selectedWlp.value === -1) || [],
    selectedExcFirms: props.selectedExcFirms || [],
    selectedBranchCodes: props.selectedBranchCodes || [],
    availableBranchCodes: props.availableBranchCodes || [],
    selectedDate: new Date(),
    expirationDate: null,
    offeringMaterial: false,
    notifyPublication: false,
    newFile: false,
    libraryDocId: null,
    previewEmails: false,
    restrictDownloads: false,
    hideFromDocCenter: false,
    internal: false,
    formSchema: {},
    formResponses: {},
    allowCompanionFile: false,
  };
};
export const initialLibraryFileInfo = (props) => {
  if (props.selectedDocument) {
    const storedFunds = JSON.parse(localStorage.getItem('recentFunds')) || [];
    const listFundsWithStored = props.funds.concat(storedFunds);

    return {
      selectedDocType: getDocumentType(props.selectedDocument.documentType, props.documentTypes),
      selectedFunds: [getByValue(props.selectedDocument.fund?.id, listFundsWithStored)],
      selectedWlp: getByValue(props.selectedDocument.whiteLabelPartner?.id, props.wlps),
      selectedDate: new Date(props.selectedDocument.effectiveDate),
      displayName: props.selectedDocument.displayName,
      newFile: false,
      formSchema: props.selectedDocument.formSchema,
      formResponses: props.selectedDocument.formResponses,
    };
  }

  return {
    selectedDocType: null,
    selectedFunds: props.fundFilterSelected ? [props.fundFilterSelected] : [],
    selectedWlp: props.selectedWhiteLabelPartner || props.wlps[0],
    selectedDate: new Date(),
    newFile: false,
    formSchema: {},
    formResponses: {},
  };
};

export const initialTemplateFileInfo = (props) => {
  if (props.selectedDocument) {
    return {
      selectedDocType: getDocumentType(props.selectedDocument.documentType, props.documentTypes),
      selectedFund: getByValue(props.selectedDocument.fund?.id, props.funds),
      selectedWlps: props.selectedDocument.whiteLabelPartners.map((wlp) => ({ label: wlp.name, value: wlp.id })),
      selectedDate: props.selectedDocument.date ? new Date(props.selectedDocument.date) : null,
      displayName: props.selectedDocument.displayName,
      newFile: false,
      orientation: props.selectedDocument.orientation,
      formSchema: props.selectedDocument.formSchema,
      formResponses: props.selectedDocument.formResponses,
    };
  }

  return {
    selectedDocType: null,
    selectedFund: null,
    selectedWlps: [],
    selectedDate: null,
    orientation: 'portrait',
    newFile: false,
    formSchema: {},
    formResponses: {},
  };
};

export const checkFileReadable = (uploadedFile) => {
  const url = 'document_manager/fund_documents/check_pdf_password';
  const form = new FormData();
  form.append('file', uploadedFile);

  return axios.post(url, form, {
    withCredentials: true,
  });
};

export function formatFunds(serializedFunds, selectedFund) {
  let funds = serializedFunds.map(
    (fund) => ({
      value: fund.id,
      label: fund.name,
    })
  );

  if (selectedFund) {
    funds.push(selectedFund);
    funds = removeDuplicates(funds);
  }

  funds.sort(sortKeepAllObjBeginning);

  return funds;
}

export function formatWlps(serializedWlps) {
  const wlps = serializedWlps.map((wlp) => ({
    value: wlp.white_label_partner.id,
    label: wlp.white_label_partner.name,
    autoRestrict: wlp.white_label_partner.fdm_auto_restrict,
  }));

  wlps.push(allWlpObj);
  wlps.push(flagshipWlp);
  wlps.sort(sortKeepAllObjBeginning);

  return wlps;
}

export function formatFirms(serializedFirms, selectedFirm) {
  let firms = serializedFirms.map(
    (firm) => ({
      value: firm.id,
      label: firm.name,
    })
  );

  if (selectedFirm && selectedFirm.value !== 0) {
    firms.push(state.selected_firm);
    firms = removeDuplicates(firms);
  }

  firms.push(allFirmsObj);
  firms.sort(sortKeepAllObjBeginning);

  return firms;
}

export const sortKeepCurrentUserFirst = (currentUserId) => (a, b) => {
  if (a.value === currentUserId) return -1;
  if (b.value === currentUserId) return 1;
  return a.label.localeCompare(b.label, 'en', { sensitivity: 'base' });
};

export function formatUsers(serializedUsers, currentUser) {
  const users = serializedUsers.map((user) => ({
    value: user.id,
    label: user.name,
  }));

  users.sort(sortKeepCurrentUserFirst(currentUser.id));

  return users;
}

export function formatBranchCodes(serializedBranchCodes) {
  const branchCodes = serializedBranchCodes.map((item) => ({
    label: item.code,
    value: item.id,
  }));

  return branchCodes;
}

export function formatFundGroups(serializedFundGroups) {
  const fundGroups = serializedFundGroups.map((item) => ({
    value: item.fund_group_id,
    label: item.fund_group_name,
    funds: item.fund_membership.map((fund) => ({
      id: fund.icn_fund_id,
      name: fund.fund_name,
    })),
  }));
  return fundGroups;
}

export function formatDocuments(serializedDocuments) {
  return serializedDocuments.map((doc) => createDocument(doc));
}

export function formatLibraryDocuments(serializedDocuments) {
  let i = 0;
  const libraryDocuments = serializedDocuments.map((doc) => createLibraryDocument(doc, i++));

  return libraryDocuments;
}

export function formatTemplateDocuments(serializedDocuments) {
  let i = 0;
  const templateDocuments = serializedDocuments.map((doc) => createFundDocumentTemplate(doc, i++));

  return templateDocuments;
}

export function updateDocumentStatus(serializedDocuments, stateDocuments) {
  const documentStatus = Array.isArray(serializedDocuments) ? serializedDocuments : [serializedDocuments];
  const newDocuments = [];

  stateDocuments.forEach((doc) => {
    const docStatus = documentStatus.find((e) => e.document_id === doc.id);
    const newDoc = doc;
    if (docStatus) {
      newDoc.status = docStatus.status;
    }
    newDocuments.push(newDoc);
  });

  return newDocuments;
}

export function formatDocumentTypes(serializedDocumentTypes) {
  const documentTypes = serializedDocumentTypes.map((item) => ({
    label: item.name,
    value: item.id,
    inDispatcher: item.configured_in_dispatcher,
    dispatcherGroup: item.dispatcher_group,
  }));

  return documentTypes;
}

export function addDocument(newDocs, documents, filtering) {
  const idsNewDocs = newDocs.map((e) => e.id);
  const docs = documents.filter((d) => !idsNewDocs.includes(d.id));

  const concatedDocs = docs.concat(newDocs);
  return concatedDocs.filter(filtering);
}

export function addLibraryDocument(newDocs, libDocuments, filtering) {
  const idsNewDocs = newDocs.map((e) => e.id);
  const libDocs = libDocuments.filter((d) => !idsNewDocs.includes(d.id));

  const concatedDocs = libDocs.concat(newDocs);
  return concatedDocs.filter(filtering);
}

export function addTemplateDocument(newDoc, templates) {
  let templateDocs = templates.filter((d) => d.id !== newDoc.id);

  const index = newDoc.position - 1;
  templateDocs.splice(index, 0, newDoc);

  return templateDocs;
}

export function updateDocument(newDoc, documents) {
  const index = documents.findIndex((e) => e.id === newDoc.id);

  if (index === -1) return documents;
  documents.splice(index, 1, newDoc);

  return [...documents];
}

export function deleteDocument(docId, documents) {
  const index = documents.findIndex((e) => e.id === docId);
  if (index === -1) return documents;

  documents.splice(index, 1);
  documents.forEach((doc, docIndex) => { doc.position = docIndex; });

  return documents;
}

export function getFundGroupAssociatedFunds(fundGroups) {
  const associatedFunds = fundGroups.flatMap((group) => group.funds).filter(
    (fund) => fund.id !== undefined && fund.id !== null
  );
  return formatFunds(associatedFunds, null) || [];
}
