import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo, createContext } from 'react';
import { useSocketChannel } from './useSocketChannel';
export const PusherContext = createContext({
    socket: undefined,
    channel: undefined,
});
export const PusherProvider = ({ children }) => {
    const { socket, channel } = useSocketChannel();
    const value = useMemo(() => ({ socket, channel }), [socket, channel]);
    return _jsx(PusherContext.Provider, { value: value, children: children });
};
export default PusherProvider;
