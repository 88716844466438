/* eslint-disable max-statements */
import React, { useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { arrayOf, shape, bool, func } from 'prop-types';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import actions from 'actions';
import Stack from '@icapitalnetwork/supernova-core/Stack';
import Badge from '@icapitalnetwork/supernova-core/Badge';
import Box from '@icapitalnetwork/supernova-core/Box';
import Menu from '@icapitalnetwork/supernova-core/Menu';
import MenuItem from '@icapitalnetwork/supernova-core/MenuItem';
import Typography from '@icapitalnetwork/supernova-core/Typography';
import IconButton from '@icapitalnetwork/supernova-core/IconButton';
import Button from '@icapitalnetwork/supernova-core/Button';
import Chip from '@icapitalnetwork/supernova-core/Chip';
import Link from '@icapitalnetwork/supernova-core/Link';

import { routeCodes } from 'router';
import { users as usersType } from 'modules/passport/prop_types/user';
import { routeCodes as routeCodesPassport } from 'modules/passport/router';
import icnReactBootstrapShape from 'prop_types/shared/icn_react_bootstrap';
import PassportIcon from './PassportIcon';
import LinkedAccount from './LinkedAccount';
import styles from './PassportMenu.module.scss';

function PassportMenu({ icnReactBootstrap, users, isPassportMenuOpen, togglePassportMenu }) {
  const passportIconRef = useRef(null);
  const history = useHistory();
  const { t } = useTranslation(['passport']);
  const {
    user,
    user: { passport_linked_users: passportUsers },
    white_label_partner: whiteLabelPartner,
  } = icnReactBootstrap;

  const handleSettingClick = () => {
    togglePassportMenu();
    history.push(routeCodesPassport.MANAGEMENT);
  };

  const handleLinkAccounts = () => {
    togglePassportMenu();
    history.push(routeCodesPassport.SELECT_ACCOUNTS, { from: routeCodes.HOME });
  };

  const currentWlp = users?.find((u) => u.wlp_id === whiteLabelPartner?.id);

  const linked = currentWlp
    ? users.filter((u) => u.icn_id !== currentWlp.icn_id && passportUsers?.includes(u.icn_id))
    : [];

  const unlinked = currentWlp
    ? users.filter((u) => u.icn_id !== currentWlp.icn_id && !passportUsers?.includes(u.icn_id))
    : [];

  const passportColor = useMemo(() => {
    const regex = /\.icn_wlp_nav_letter\s*{[^}]*color:\s*([^;}]+)/;
    const match = icnReactBootstrap?.wlpStyles?.match(regex);
    return match ? match[1] : 'white';
  }, [icnReactBootstrap.wlpStyles]);

  return (
    <>
      {unlinked.length > 0 && (
        <Badge
          className={styles.badge}
          variant="dot"
          color="error"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClick={togglePassportMenu}
        >
          <PassportIcon ref={passportIconRef} color={passportColor} />
        </Badge>
      )}
      {unlinked.length === 0 && (
        <Box onClick={togglePassportMenu}>
          <PassportIcon ref={passportIconRef} color={passportColor} />
        </Box>
      )}
      <Menu
        id="passport-menu"
        className={styles.passport_menu}
        anchorEl={passportIconRef.current}
        open={isPassportMenuOpen}
        onClose={togglePassportMenu}
        MenuListProps={{
          'aria-labelledby': 'passport-button',
        }}
        transformOrigin={{ vertical: -10, horizontal: 15 }}
      >
        <Box className={styles.user_info}>
          <Stack direction="column" spacing={1.5} sx={{ width: '100%' }}>
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
              <Box>
                <Typography variant="h6">{user.name}</Typography>
                <Typography className={styles.email} variant="smallParagraph" my={0.3}>
                  {user.email}
                </Typography>
              </Box>
              <IconButton className={styles.setting_button} onClick={handleSettingClick}>
                <SettingsOutlinedIcon />
              </IconButton>
            </Stack>
            <LinkedAccount wlp={currentWlp} isCurrentWlp />
          </Stack>
        </Box>
        {linked.length > 0 && (
          <Box className={styles.linked_accounts}>
            {linked.map((account) => (
              <Link href={`http://${account.domain}`} className={styles.link} key={account.wlp_name}>
                <MenuItem className={styles.list_item_padding} key={account.wlp_name}>
                  <LinkedAccount wlp={account} />
                </MenuItem>
              </Link>
            ))}
          </Box>
        )}
        {unlinked.length > 0 && (
          <Box className={styles.unlinked_items}>
            <Stack direction="column" spacing={0.25} sx={{ width: '100%' }}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Chip className={styles.unregistered} label={unlinked.length} color="error" size="small" />
                <Typography>{t('passport:other_accounts_registered', 'Other accounts registered')}</Typography>
              </Stack>
              <Box sx={{ textAlign: 'right' }}>
                <Button variant="text" onClick={handleLinkAccounts}>
                  {t('passport:link_accounts', 'Link Accounts')}
                </Button>
              </Box>
            </Stack>
          </Box>
        )}
      </Menu>
    </>
  );
}

function mapStateToProps(state) {
  return {
    icnReactBootstrap: state.icnReactBootstrap.icn_react_bootstrap,
    users: state.passport.users,
    isPassportMenuOpen: state.passport.isPassportMenuOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      togglePassportMenu: actions.togglePassportMenu,
    },
    dispatch
  );
}

PassportMenu.defaultProps = {
  icnReactBootstrap: null,
  users: [],
  isPassportMenuOpen: false,
  togglePassportMenu: () => {},
};

PassportMenu.propTypes = {
  icnReactBootstrap: icnReactBootstrapShape,
  users: arrayOf(shape(usersType)),
  isPassportMenuOpen: bool,
  togglePassportMenu: func,
};

export default connect(mapStateToProps, mapDispatchToProps)(PassportMenu);
