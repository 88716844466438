import React, { useState, useEffect } from 'react';
import { bool, shape } from 'prop-types';
import { connect } from 'react-redux';
import PassportSnackbar from 'modules/passport/components/PassportSnackbar';
import AccessAgreementsSnackbar from 'components/access_agreements/AccessAgreementsSnackbar';
import AccessAgreements from 'components/access_agreements/AccessAgreements';
import {
  isAgreementsModalOpen as isAgreementsModalOpenSelector,
  getAgreementsRequestFailed,
} from 'reducers/access_agreements_reducers/selectors';
import { getUser } from '../../../../selectors/icnBootstrapSelectors';
import MarketingBanner from '../../components/home/MarketingBanner/MarketingBanner';
import CoolOffNotice from '../../../../containers/cool_off_notice/CoolOffNotice';
import styles from './index.module.scss';

const BannerHandler = ({ user, agreementsRequestFailed, isAgreementsModalOpen }) => {
  const [isPassportLinked, setIsPassportLinked] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('prevPage') === 'welcome') setIsPassportLinked(true);
  }, []);

  const viewPassportSnackbar = !user.is_imposter && isPassportLinked && user.canSeePassport;

  return (
    <>
      <CoolOffNotice />
      <MarketingBanner className={styles.marketingBanner} />
      {viewPassportSnackbar && <PassportSnackbar />}
      {agreementsRequestFailed && <AccessAgreementsSnackbar />}
      {isAgreementsModalOpen && <AccessAgreements />}
    </>
  );
};

BannerHandler.propTypes = {
  user: shape({
    is_imposter: bool,
    canSeePassport: bool,
  }),
  agreementsRequestFailed: bool,
  isAgreementsModalOpen: bool,
};

BannerHandler.defaultProps = {
  user: { is_imposter: false, canSeePassport: false },
  agreementsRequestFailed: false,
  isAgreementsModalOpen: false,
};

const mapStateToProps = (state) => {
  return {
    user: getUser(state),
    agreementsRequestFailed: getAgreementsRequestFailed(state),
    isAgreementsModalOpen: isAgreementsModalOpenSelector(state),
  };
};

export default connect(mapStateToProps)(BannerHandler);
