import { shape, string, number, bool, oneOfType, arrayOf, oneOf } from 'prop-types';
import investmentShareClassShape from '../investment_share_class';

export default shape({
  id: oneOfType([string, number]),
  classification: string,
  investor_name: string,
  investor_email: string,
  amount: oneOfType([string, number, shape({ currency: string, value: number })]),
  approval_status: string,
  approval_status_override: string,
  investor_profile_id: number,
  review_status: string,
  hf_funded: bool,
  fund_name: string,
  share_class_data: shape({
    id: number,
    short_name: string,
    description: string,
    name: string,
  }),
  investment_share_class: investmentShareClassShape,
  custodian_integration_statuses: shape({
    eligibility: shape({ status: string, message: string }),
    document_delivery: shape({ status: string, message: string }),
    order: shape({ status: string, message: string }),
  }),
  investor_profile: shape({
    id: number,
    name: string,
  }),
  redemption_terms: shape({}),
  can_refresh_data: bool,
  inv_notes: arrayOf(
    shape({
      white_label_partner_id: number,
    })
  ),
  assigned_lead_approver: shape({
    id: number,
    name: string,
  }),
  parent_nominee_investment: bool,
  bulk_discretionary: bool,
  process_status: oneOf(['idle', 'closing']),
});
