// Exported environments. Check icn_react_controller for more.
const {
  HONEYBADGER_JS_API_KEY,
  ENABLE_FULLSTORY,
  ENABLE_APPCUES,
  PUSHER_API_KEY,
  PUSHER_CLUSTER,
  PUSHER_WS_HOST,
  PUSHER_WS_PORT,
  PUSHER_HOST,
  PUSHER_PORT,
  DISABLE_PUSHER,
  RAILS_ENV,
  GP_ADMIN_INVITE_LIMIT,
  // AG_GRID_LICENSE,
  PUBLIC_URL,
  CLOUDFRONT_URL,
  OKTA_ISSUER,
} = window.envExporter;

const configuration = {
  // set at run time
  HONEYBADGER_JS_API_KEY,
  ENABLE_FULLSTORY,
  ENABLE_APPCUES,
  PUSHER_API_KEY,
  PUSHER_CLUSTER,
  PUSHER_WS_HOST,
  PUSHER_WS_PORT,
  PUSHER_HOST,
  PUSHER_PORT,
  DISABLE_PUSHER,
  RAILS_ENV,
  GP_ADMIN_INVITE_LIMIT,
  // AG_GRID_LICENSE,
  PUBLIC_URL,
  CLOUDFRONT_URL,
  OKTA_ISSUER,
  // set at build time
  NODE_ENV: process.env.NODE_ENV,
  GIT_COMMIT_HASH: process.env.GIT_COMMIT_HASH,
};

export default configuration;
