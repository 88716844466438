import { getIsUnifiedHomepageEarlyAccessEnabled } from '../../components/UnifiedHomepagePromo/utils';

export const isHomepageMFEEnabled = (icnReactBootstrap) => {
  const isMultiAsset = icnReactBootstrap?.user?.permissions?.includes?.('see_unified_homepage_multi_asset');
  const hasHomepagePermissions =
    icnReactBootstrap?.user?.permissions?.includes?.('see_unified_homepage') || isMultiAsset;

  const isPromoOptionEnabled = icnReactBootstrap?.user?.permissions?.includes?.('see_unified_homepage_promo');
  if (!hasHomepagePermissions) {
    return false;
  }
  if (isPromoOptionEnabled) {
    return getIsUnifiedHomepageEarlyAccessEnabled();
  }
  return true;
};
