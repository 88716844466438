import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  AlertTitle,
  Link,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@icapitalnetwork/supernova-core';
import { formatDate } from 'services/DateUtils';
import { bool, number, shape, string } from 'prop-types';
import { getUser } from '../../selectors/icnBootstrapSelectors';

const modalContent = (t) => (
  <DialogContentText>
    {t('fund_profile:cool_off_notice.waiting_period')}
    {t('fund_profile:cool_off_notice.you_will_receive_email')}
    <br />
    <br />
    {t('fund_profile:cool_off_notice.private_placements')}
    <br />
    <br />
    {t('fund_profile:cool_off_notice.further_assistance.text')}
    {' '}
    <Link variant="paragraph" href="tel:212-994-7333">212-994-7333</Link>
    {' '}
    {t('fund_profile:cool_off_notice.further_assistance.or')}
    {' '}
    <Link variant="paragraph" href="mailto:ir@icapitalnetwork.com">ir@icapitalnetwork.com</Link>
    .
  </DialogContentText>
);

export function CoolOffNotice({ user, fundCooledOffOverride }) {
  const { t } = useTranslation(['fund_profile']);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

 if (!user || user.cooled_off || !user.can_invest || user.cooledOffOverrides || fundCooledOffOverride) {
    return null;
  }

  return (
    <Alert severity="warning" multiline="true" sx={{ width: '100%', zIndex: 1 }}>
      <AlertTitle data-testid="alert-title">
        {user.cooled_off_days_remaining}
        {' '}
        {t('common:day', { count: user.cooled_off_days_remaining })}
        {' '}
        {t('fund_profile:cool_off_notice.days_left')}
        {' '}
        <Button data-testid="learn-more-button" variant="text" color="primary" size="lg" onClick={handleClickOpen}>
          {t('fund_profile:cool_off_notice.learn_more')}
        </Button>
      </AlertTitle>
      <Box data-testid="alert-body" sx={{ pb: 0.5 }}>
        {t('fund_profile:cool_off_notice.you_are_in_cool_off')}
        {' '}
        {formatDate(user.cooled_off_at, 'MM/dd/yy')}
        .
        {' '}
        {t('fund_profile:cool_off_notice.if_further_assistance')}
        {' '}
        <Link variant="paragraph" href="tel:212-994-7333">212-994-7333</Link>
        {' '}
        {t('fund_profile:cool_off_notice.further_assistance.or')}
        {' '}
        <Link variant="paragraph" href="mailto:ir@icapitalnetwork.com">ir@icapitalnetwork.com</Link>
        .
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle data-testid="dialog-title" onClose={handleClose} sx={{ paddingRight: '48px' }}>
          {t('fund_profile:cool_off_notice.available_in')}
          {' '}
          {user.cooled_off_days_remaining}
          {' '}
          {t('common:day', { count: user.cooled_off_days_remaining })}
          .
        </DialogTitle>
        <DialogContent data-testid="dialog-content">
          {modalContent(t)}
        </DialogContent>
        <DialogActions>
          <Button data-testid="close-dialog-button" autoFocus onClick={handleClose} variant="text" size="medium" color="primary">
            {t('common:close')}
          </Button>
        </DialogActions>
      </Dialog>
    </Alert>
  );
}

const mapStateToProps = (state) => ({
  user: getUser(state),
});

CoolOffNotice.propTypes = {
  user: shape({
    cooled_off: bool,
    cooled_off_days_remaining: number,
    cooled_off_at: string,
  }),
  fundCooledOffOverride: bool,
};

CoolOffNotice.defaultProps = {
  user: null,
  fundCooledOffOverride: false,
};
export default connect(mapStateToProps, null)(CoolOffNotice);
