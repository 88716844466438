/* eslint-disable max-statements */
import actions from 'actions';
import faviconIcon from 'images/favicon.ico';
import fullStory from 'services/fullstory';
import appCues from 'services/app_cues';
import usersnap from 'services/usersnap';
import { getRequest } from 'actions/utils';
import { hasPartnerProxyAPIPrefix } from 'services/axios/setBaseUrl';
import configuration from 'configuration';

export const disableThirdPartyJs = () => hasPartnerProxyAPIPrefix();

export const addWlpStyles = (wlpStyles) => {
  if (wlpStyles) {
    const whiteLabelStyleTag = document.createElement('style');
    whiteLabelStyleTag.id = 'wlp-react-styles';
    whiteLabelStyleTag.innerHTML = wlpStyles;
    document.head.appendChild(whiteLabelStyleTag);
  }
};

export const setPageTitle = (title) => {
  if (title) {
    document.title = title;
  }
};

export const addFaviconIcon = (favicon) => {
  const faviconLinkTag = document.createElement('link');
  faviconLinkTag.id = 'icn-react-favicon';
  faviconLinkTag.type = 'image/x-icon';
  faviconLinkTag.rel = 'shortcut icon';
  faviconLinkTag.href = favicon === 'favicon.ico' ? faviconIcon : favicon;
  document.head.appendChild(faviconLinkTag);
};

export const bootstrapObjBuilder = (response, mainAppUrl) => {
  const bootstrapObj = {};
  bootstrapObj.icnBootstrap = response.icn_bootstrap;
  bootstrapObj.wlpStyles = response.react_white_label_styles;
  bootstrapObj.favicon = response.favicon;
  bootstrapObj.header_logo = response.header_logo;
  bootstrapObj.public_logo = response.public_logo;
  bootstrapObj.csrf_token = response.csrf_token;
  bootstrapObj.mainAppUrl = mainAppUrl;
  bootstrapObj.api_token = response.api_token;
  return bootstrapObj;
};

export const mainAppUrl = () => {
  let appHostname = window.location.host;
  const pathWithoutReactBase = new URL(window.location.href).pathname.split(/\/icn_react/)[0];
  // logic for local environment
  switch (configuration.RAILS_ENV) {
    case 'development':
      appHostname = appHostname.split(':');
      appHostname.pop();
      appHostname.push(3000);
      appHostname = appHostname.join(':');
      break;
    case 'test':
      appHostname = appHostname.split(':');
      appHostname.pop();
      appHostname.push(31337);
      appHostname = appHostname.join(':');
      break;
    default:
      break;
  }
  const url = `${window.location.protocol}//${appHostname}${pathWithoutReactBase}`;
  return url;
};

export const fetchBootstrap = () => {
  const url = 'users/react_bootstrap_identity';
  return getRequest({ url });
};

export const onBootstrapRetrieved = (response, dispatch) => {
  // this call will always return 200
  // check the user object in response to decide redirect or keep on
  // add white label styles and favicon icon to the page
  const bootstrapObj = bootstrapObjBuilder(response, mainAppUrl());
  dispatch(actions.setIcnBootstrap(bootstrapObj));
  addWlpStyles(bootstrapObj.wlpStyles);
  setPageTitle(bootstrapObj.icnBootstrap.white_label_partner.name);
  addFaviconIcon(bootstrapObj.favicon);

  const env = bootstrapObj.icnBootstrap.env;
  const user = bootstrapObj.icnBootstrap.user;

  const fullStoryEnabled = configuration.ENABLE_FULLSTORY;
  const appcuesEnabled = configuration.ENABLE_APPCUES;
  const whiteLabelPartner = bootstrapObj.icnBootstrap.white_label_partner;
  const isWhiteLabel = whiteLabelPartner.id !== 0;
  const enableWhiteLabelFullStory =
    bootstrapObj.icnBootstrap.white_label_partner && bootstrapObj.icnBootstrap.white_label_partner.enable_fullstory;
  const firstRole = bootstrapObj.icnBootstrap.first_role;
  const domain = isWhiteLabel ? whiteLabelPartner.domain : null;

  if (!disableThirdPartyJs()) {
    usersnap(env, isWhiteLabel, whiteLabelPartner);
    fullStory(user, firstRole, domain, fullStoryEnabled && (!isWhiteLabel || enableWhiteLabelFullStory));

    if (user) {
      const roles = user.roles;
      const groups = user.groups;
      const permissions = user.permissions;

      appCues(user, whiteLabelPartner, env, domain, 'us', roles, groups, permissions, appcuesEnabled);
    }
  }

  if (user && !user.is_imposter) {
    dispatch(actions.checkOneTimePopup());

    if (
      !user.active_main_site_terms_accepted &&
      isWhiteLabel &&
      !user.active_wlp_terms_accepted &&
      !user.enable_custom_wlp_page
    ) {
      dispatch(actions.getActiveMainSiteTerm()).then(() => {
        dispatch(actions.getActiveWlpTerm(whiteLabelPartner.id));
      });
    } else if (isWhiteLabel && !user.active_wlp_terms_accepted) {
      dispatch(actions.getActiveWlpTerm(whiteLabelPartner.id));
    } else if (
      !user.active_main_site_terms_accepted &&
      (!isWhiteLabel || (isWhiteLabel && !user.enable_custom_wlp_page))
    ) {
      dispatch(actions.getActiveMainSiteTerm());
    }
  }
};

export const initializeBootstrap = (dispatch) =>
  dispatch(fetchBootstrap()).then((response) => onBootstrapRetrieved(response, dispatch));
