// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PassportSnackbar-module__passport_snackbar___S8okD{display:flex;flex-direction:row;align-items:center;justify-content:space-evenly}`, "",{"version":3,"sources":["webpack://./icn_react/src/modules/passport/components/PassportSnackbar.module.scss"],"names":[],"mappings":"AAAA,oDACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,4BAAA","sourcesContent":[".passport_snackbar {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-evenly;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"passport_snackbar": `PassportSnackbar-module__passport_snackbar___S8okD`
};
export default ___CSS_LOADER_EXPORT___;
