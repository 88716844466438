import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@icapitalnetwork/supernova-core';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import actions from 'actions/access_agreements_actions/accessAgreementsActions';
import { bindActionCreators } from 'redux';
import { first } from 'lodash';
import {
  scrollRequiredLabel,
  checkboxRequiredLabel,
} from 'constants/access_agreements';
import {
  accessAgreement as accessAgreementType,
  accessAgreements as accessAgreementsType,
} from 'modules/investment_opportunity/prop_types/accessAgreement';
import {
  getAgreements,
  getOnAgreementsCleared,
} from 'reducers/access_agreements_reducers/selectors';
import { useSnackbar } from '@icapitalnetwork/supernova-hooks';
import { useTheme } from '@mui/material';
import AccessAgreementsDialogContent from './AccessAgreementsDialogContent';

function AccessAgreementsDialog({ agreement, acceptAgreement, onCloseModal }) {
  const {
    requireScrollToAccept = false,
    title,
    text: agreementText,
    checkboxText,
  } = agreement;
  const [hasUserEnabledCheckbox, setHasUserEnabledCheckbox] = useState(false);
  const [hasUserScrolledAgreement, setHasUserScrolledAgreement] = useState(
    !requireScrollToAccept
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();
  const { t } = useTranslation();

  const handleClickAccept = useCallback(() => {
    if (!hasUserScrolledAgreement) {
      return enqueueSnackbar(scrollRequiredLabel, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        onCloseAction: (id) => closeSnackbar(id),
      });
    }

    if (!hasUserEnabledCheckbox) {
      return enqueueSnackbar(checkboxRequiredLabel, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        onCloseAction: (id) => closeSnackbar(id),
      });
    }

    return acceptAgreement(agreement);
  }, [acceptAgreement, agreement, closeSnackbar, enqueueSnackbar, hasUserEnabledCheckbox, hasUserScrolledAgreement]);

  const handleClickCheckbox = useCallback(() => {
    if (!hasUserScrolledAgreement) {
      return enqueueSnackbar(scrollRequiredLabel, {
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
        onCloseAction: (id) => closeSnackbar(id),
      });
    }

    return setHasUserEnabledCheckbox((v) => !v);
  }, [closeSnackbar, enqueueSnackbar, hasUserScrolledAgreement]);

  return (
    <Dialog maxWidth="sm" fullWidth open size="lg">
      <DialogTitle onClose={onCloseModal}>{title}</DialogTitle>
      <DialogContent>
        <AccessAgreementsDialogContent
          agreementText={agreementText}
          checkboxText={checkboxText}
          requireScrollToAccept={requireScrollToAccept}
          hasUserEnabledCheckbox={hasUserEnabledCheckbox}
          setHasUserEnabledCheckbox={setHasUserEnabledCheckbox}
          hasUserScrolledAgreement={hasUserScrolledAgreement}
          setHasUserScrolledAgreement={setHasUserScrolledAgreement}
          handleClickCheckbox={handleClickCheckbox}
        />
      </DialogContent>
      <DialogActions sx={{ columnGap: '20px' }}>
        <Button onClick={onCloseModal} variant="text">
          {t('common:cancel', 'Cancel')}
        </Button>
        <Button
          sx={
            !hasUserEnabledCheckbox ? {
              color: theme.palette.disabledContrast.disabledText,
              backgroundColor: theme.palette.action.disabled,
              '&:hover': {
                backgroundColor: theme.palette.action.disabled,
              },
            } : null
          }
          aria-label={!hasUserEnabledCheckbox ? checkboxRequiredLabel : null}
          onClick={handleClickAccept}
        >
          {t('common:accept', 'Accept')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function AccessAgreements({
  agreements,
  closeAgreementsModal,
  acceptAgreement,
}) {
  const currentAgreement = first(agreements);

  const onCloseAgreementsModal = () => {
    closeAgreementsModal();
  };

  return (
    <AccessAgreementsDialog
      agreement={currentAgreement}
      acceptAgreement={acceptAgreement}
      onCloseModal={onCloseAgreementsModal}
    />
  );
}

function mapStateToProps(state) {
  return {
    agreements: getAgreements(state),
    onAgreementsCleared: getOnAgreementsCleared(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      acceptAgreement: actions.onAcceptAccessAgreement,
      closeAgreementsModal: actions.aaCloseAgreementsModal,
    },
    dispatch
  );
}

AccessAgreementsDialog.propTypes = {
  agreement: accessAgreementType.isRequired,
  acceptAgreement: func.isRequired,
  onCloseModal: func.isRequired,
};

AccessAgreementsDialog.defaultProps = {};

AccessAgreements.propTypes = {
  agreements: accessAgreementsType,
  closeAgreementsModal: func.isRequired,
  acceptAgreement: func.isRequired,
};

AccessAgreements.defaultProps = {
  agreements: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessAgreements);
