import React from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';

const iconStyle = Object.freeze({ mr: 1, fontSize: '16px' });

export const TODO_ICONS = Object.freeze({
  attention_required: <WarningAmberIcon color="error" sx={iconStyle} />,
  pending_subscription_documents: <DriveFileRenameOutlineIcon sx={iconStyle} />,
  required_docs_incomplete: <TextSnippetOutlinedIcon sx={iconStyle} />,
  attestation_required: <CheckCircleOutlinedIcon sx={iconStyle} />,
  new_comments: <CommentOutlinedIcon sx={iconStyle} />,
});
