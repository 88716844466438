import { ADVISOR_ROLES } from '../complete_profile/profileData';
import { attestationGroups, getRoleKey } from './sections/attestationData';

const validateAttestations = (profile, groupKey) => {
  const groupKeys = attestationGroups[groupKey] || [];
  return groupKeys.every((key) => profile[key]);
};

const isAttestationAccepted = (profile, role, whiteLabelPartnerId) => {
  if ((role === 'ria' || ADVISOR_ROLES.includes(role)) && whiteLabelPartnerId === 0) {
    const groupKey = getRoleKey(profile.role);
    return profile.attestation && validateAttestations(profile, groupKey);
  }
  return profile.attestation;
};

function useQualification(role, profile, qualificationQuestions, whiteLabelPartnerId) {
  const questionIds = qualificationQuestions.filter((question) => question.checked).map((q) => q.id);
  const allInputsFilled = () => {
    if (questionIds.length > 0) {
      return isAttestationAccepted(profile, role, whiteLabelPartnerId);
    }
    return false;
  };

  return {
    allInputsFilled,
  };
}

export { isAttestationAccepted, useQualification, validateAttestations };
