import actionFactory from 'services/actions';
import { getRequest, postRequest } from 'actions/utils';
import {
  types,
  getLegacyAgreements,
  getAccessAgreementsStandarized,
} from 'services/access_agreements/accessAgreementsHelper';
import {
  AA_OPEN_AGREEMENTS_MODAL,
  AA_CLOSE_AGREEMENTS_MODAL,
  AA_ACCEPT_AGREEMENT,
  AA_ACCEPT_LEGACY_AGREEMENT,
  AA_SET_REQUEST_FAILED,
} from '../actionsConstants';

const aaOpenAgreementsModal = actionFactory(AA_OPEN_AGREEMENTS_MODAL);
const aaCloseAgreementsModal = actionFactory(AA_CLOSE_AGREEMENTS_MODAL);
const aaAcceptAgreement = actionFactory(AA_ACCEPT_AGREEMENT);
const aaAcceptLegacyAgreement = actionFactory(AA_ACCEPT_LEGACY_AGREEMENT);
const aaSetRequestFailed = actionFactory(AA_SET_REQUEST_FAILED);

function acceptAccessAgreement(agreementId) {
  const accessAgreementsQueryParams = {
    agreement_types: {
      private_access_fund_access_agreement: agreementId,
    },
  };
  return postRequest({
    url: 'access_agreements',
    params: accessAgreementsQueryParams,
    showSpinner: true,
    onSuccess: () => aaAcceptAgreement({ agreementId }),
    onFailure: () => aaSetRequestFailed(true),
  });
}

function acceptNonDisclosureAgreement() {
  return (dispatch, getState) => {
    const { private_access_fund_id: pafId } = getState().accessAgreements.fund;
    return dispatch(postRequest({
      url: 'non_disclosure_agreements',
      data: {
        paf_id: pafId,
      },
      onSuccess: () => aaAcceptLegacyAgreement({ type: types.NON_DISCLOSURE_AGREEMENT }),
      onFailure: () => aaSetRequestFailed(true),
    }));
  };
}

function acceptInvestmentDiligenceDisclaimer() {
  return (dispatch, getState) => {
    const { fund_id: fundId } = getState().accessAgreements.fund;
    dispatch(postRequest({
      url: `funds/${fundId}/accept_investment_diligence_disclaimer`,
      onSuccess: () => aaAcceptLegacyAgreement({ type: types.INVESTMENT_DILIGENCE }),
      onFailure: () => aaSetRequestFailed(true),
    }));
  };
}

function acceptLegacyAgreement(type) {
  if (type === types.NON_DISCLOSURE_AGREEMENT) {
    return acceptNonDisclosureAgreement();
  }
  if (type === types.INVESTMENT_DILIGENCE) {
    return acceptInvestmentDiligenceDisclaimer();
  }
  return null;
}

function onAcceptAccessAgreement({ type, agreementId }) {
  if (type === types.ACCESS_AGREEMENT) {
    return acceptAccessAgreement(agreementId);
  }
  return acceptLegacyAgreement(type);
}

function aaLaunchAgreementsModal(fund, onAgreementsCleared = () => {}) {
  return (dispatch, getState) => {
    const user = getState().icnReactBootstrap.icn_react_bootstrap.user;
    const { acceptedLegacyAgreements } = getState().accessAgreements;
    const legacyAgreements = getLegacyAgreements(fund, user, acceptedLegacyAgreements);
    const accessAgreementsQueryParams = {
      agreement_types: {
        private_access_fund_access_agreement: {
          paf_id: fund.private_access_fund_id,
        },
      },
    };
    return dispatch(
      getRequest({
        url: 'access_agreements',
        params: accessAgreementsQueryParams,
        showSpinner: true,
        onSuccess: (payload) => {
          const accessAgreements = getAccessAgreementsStandarized(
            payload.private_access_fund_access_agreement
          );
          return aaOpenAgreementsModal({
            fund,
            agreements: [...legacyAgreements, ...accessAgreements],
            onAgreementsCleared,
          });
        },
        onFailure: () => aaSetRequestFailed(true),
      })
    );
  };
}

export default {
  aaLaunchAgreementsModal,
  aaCloseAgreementsModal,
  onAcceptAccessAgreement,
  aaSetRequestFailed,
};
