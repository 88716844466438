import React from 'react';
import { bool, func, string } from 'prop-types';
import { FormControlLabel, Checkbox } from '@icapitalnetwork/supernova-core';

import { useTheme } from '@mui/material';

import { scrollRequiredLabel } from 'constants/access_agreements';

function AccessAgreementCheckbox({
  checked, onClick, label, disabled,
}) {
  const theme = useTheme();

  return (
    <FormControlLabel
      sx={
        disabled ? {
          '& .MuiButtonBase-root.MuiCheckbox-root': {
            color: theme.palette.action.disabled,
          },
          '& .MuiTypography-paragraph.MuiFormControlLabel-label': {
            color: theme.palette.text.disabled,
          },
        } : null
      }
      control={<Checkbox checked={checked} onClick={onClick} />}
      label={label}
      aria-label={disabled && scrollRequiredLabel}
    />
  );
}

AccessAgreementCheckbox.propTypes = {
  checked: bool,
  onClick: func,
  label: string,
  disabled: bool,
};

AccessAgreementCheckbox.defaultProps = {
  checked: false,
  onClick: () => {},
  label: '',
  disabled: false,
};

export default AccessAgreementCheckbox;
