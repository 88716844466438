/* eslint-disable import/prefer-default-export */
const filterParamsMapping = {
  custodians: 'selectedCustodians',
  rias_firms: 'selectedRiaFirms',
  rias_users: 'selectedRiaUsers',
  sign_methods: 'selectedUploadMethods',
  lead_approvers: 'selectedLeadApprovers',
  wlps: 'selectedWlps',
  show_cancelled_investments: 'showCancelledInvestments',
  branch_codes: 'selectedBranchCodes',
  referral_codes: 'selectedReferralCodes',
  new_comment_only: 'newCommentOnly',
};

/*
  Note about negative filter options, e.g. 'No Custodian', etc
  when in truncation mode (fetching filters from endpoint)
  1) For custodians, the front-end appends the option to the list of filters.
  Backend handles the nil value
  2) For upload_methods, the backend transforms an option into nil
  3) For firms, not supported currently
*/
// prepares selected filters for API request
export const selectedFilters = (selectedFiltersObj = {}) =>
  Object.keys(filterParamsMapping).reduce((memo, curr) => {
    const newKey = filterParamsMapping[curr];
    memo[newKey] = selectedFiltersObj[curr];

    if (Array.isArray(memo[newKey]) && !memo[newKey].length) {
      memo[newKey] = null;
    }

    return memo;
  }, {});
