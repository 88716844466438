import { useEffect, useMemo, useState } from 'react';
import Pusher from 'pusher-js';
import { postRequest } from 'services/apiRequest';
import { useCurrentUser } from 'hooks/current_user';
import { useGlobalHandlers } from './useGlobalHandlers';
import { getPusherConfig } from './config';
const PUSHER_AUTH_ENDPOINT = 'pusher/auth';
function createSocket(pusherKey, options) {
    return new Pusher(pusherKey, Object.assign({ forceTLS: true, authorizer: (channel) => ({
            authorize: (socketId, callback) => {
                const params = {
                    channel_name: channel.name,
                    socket_id: socketId,
                };
                postRequest({ url: PUSHER_AUTH_ENDPOINT, params }).then((response) => {
                    callback(null, response);
                });
            },
        }) }, options));
}
export function useSocketChannel() {
    const user = useCurrentUser();
    const socket = useMemo(() => {
        const { pusherKey, options } = getPusherConfig();
        if (!pusherKey)
            return undefined;
        return createSocket(pusherKey, options);
    }, []);
    const [channel, setChannel] = useState(undefined);
    const { initializeGlobalHandlers } = useGlobalHandlers();
    useEffect(() => {
        if (user && socket) {
            const newChannel = socket.subscribe(`private-${user.id}`);
            setChannel(newChannel);
            initializeGlobalHandlers(newChannel);
        }
        return () => {
            if (user && socket) {
                socket === null || socket === void 0 ? void 0 : socket.unsubscribe(`private-${user.id}`);
                setChannel(undefined);
            }
        };
    }, [user, socket, initializeGlobalHandlers]);
    return {
        socket,
        channel,
    };
}
