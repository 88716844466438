import { getRequest, patchRequest } from 'services/apiRequest';
import { FALLBACK_ERROR_MESSAGE } from 'containers/client_management/constants';

export const patchRefreshData = (investmentId, privateAccessFundId, investorData) =>
  patchRequest({
    url: `client_management/external_apis/investments/${investmentId}/refresh_data`,
    data: {
      transaction_uuid: investorData.transactionId,
      private_access_fund_id: privateAccessFundId,
      selected_signers: investorData.selectedSigners,
      added_signers: investorData.availableSigners.filter(
        (signer) => signer.manually_added && investorData.selectedSigners[signer.external_id]
      ),
    },
    errorMessage: FALLBACK_ERROR_MESSAGE,
  });

export const getUpdatedInvestment = (investmentId) =>
  getRequest({
    url: `client_management/investments/${investmentId}`,
    errorMessage: FALLBACK_ERROR_MESSAGE,
  });
