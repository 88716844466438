import './public_path';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ErrorBoundary } from 'services/system_notifier';
import { IcnSkeletonTheme } from '@icapitalnetwork/react-component-library';
import { Provider as SuperNovaProvider, SnackbarProvider } from '@icapitalnetwork/supernova-core';

// ag-grid deps
import 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import { AG_GRID_LICENSE } from 'constants/index';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import '@icapitalnetwork/react-component-library/dist/index.css';
// Remove this when all component are from supernova
import '@fontsource/roboto-flex';
import '@fontsource/roboto-flex/variable.css';

import 'bootstrap/scss/bootstrap.scss';
import './index.scss';
import './services/ag_grid/utils.scss';

import { I18nextProvider } from 'react-i18next';
import store from './icnStore';

import 'initializer';

import i18n from './i18n';
import App from './App';
import {
  ReactQueryProvider,
  RouterProvider,
  CurrentUserProvider,
  CurrentWhiteLabelPartnerProvider,
  PusherProvider,
} from './providers';

LicenseManager.setLicenseKey(AG_GRID_LICENSE);

window.store = store;

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <ReactQueryProvider>
        <DndProvider backend={HTML5Backend}>
          <IcnSkeletonTheme>
            <SuperNovaProvider>
              <SnackbarProvider maxSnack={3}>
                <I18nextProvider i18n={i18n}>
                  <CurrentUserProvider>
                    <CurrentWhiteLabelPartnerProvider>
                      <RouterProvider>
                        <PusherProvider>
                          <App />
                        </PusherProvider>
                      </RouterProvider>
                    </CurrentWhiteLabelPartnerProvider>
                  </CurrentUserProvider>
                </I18nextProvider>
              </SnackbarProvider>
            </SuperNovaProvider>
          </IcnSkeletonTheme>
        </DndProvider>
      </ReactQueryProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root')
);
