/* eslint-disable max-lines-per-function */
import {
  Box,
  LinearProgress,
  Typography,
} from '@icapitalnetwork/supernova-core';
import { useTheme } from '@mui/material';
import { modalSubtitle } from 'constants/access_agreements';
import {
  bool, func, oneOfType, string, node, shape,
} from 'prop-types';
import React, {
  useRef, useEffect, useState,
} from 'react';
import AccessAgreementCheckbox from './AccessAgreementCheckbox';

function scrollTracker(
  agreementBodyRef,
  hasUserReviewed,
  progressBarPercentage
) {
  if (agreementBodyRef.current) {
    const { scrollTop, scrollHeight, offsetHeight } = agreementBodyRef.current;
    const progress = ((scrollTop + offsetHeight) / scrollHeight) * 100;
    if (progressBarPercentage) {
      progressBarPercentage((current) => {
        if (progress >= 100) {
          return 100;
        }
        if (progress > current) {
          return progress;
        }
        return current;
      });
    }
    if (scrollTop + offsetHeight >= scrollHeight) {
      hasUserReviewed(true);
    }
  }
}

function AccessAgreementsDialogContent({
  agreementText,
  checkboxText,
  requireScrollToAccept,
  hasUserEnabledCheckbox,
  setHasUserEnabledCheckbox,
  hasUserScrolledAgreement,
  setHasUserScrolledAgreement,
  handleClickCheckbox,
  initialState,
}) {
  const agreementBodyRef = useRef();
  const theme = useTheme();
  const [agreementProgressBar, setAgreementProgressBar] = useState(initialState?.scrolled ? 100 : 0);
  const [refAssigned, setRefAssigned] = useState(false);

  useEffect(() => {
    if (!initialState) {
      setHasUserEnabledCheckbox(false);
      setHasUserScrolledAgreement(!requireScrollToAccept);
    }

    if (refAssigned && agreementBodyRef.current.scroll) {
      agreementBodyRef.current?.scroll(0, 0);
    }
  }, [agreementText, refAssigned, requireScrollToAccept, setHasUserEnabledCheckbox, setHasUserScrolledAgreement, initialState]);

  useEffect(() => {
    if (!initialState?.scrolled && refAssigned && agreementBodyRef.current) {
      scrollTracker(
        agreementBodyRef,
        setHasUserScrolledAgreement,
        setAgreementProgressBar
      );
    }
    return () => {
      if (!initialState?.scrolled) setAgreementProgressBar(0);
    };
  }, [hasUserScrolledAgreement, setHasUserScrolledAgreement, refAssigned, initialState]);

  return (
    <>
      {requireScrollToAccept && (
        <Typography
          variant="h5"
          sx={{ paddingTop: theme.spacing(1), paddingBottom: theme.spacing(2) }}
        >
          {modalSubtitle}
        </Typography>
      )}
      {requireScrollToAccept && (
        <Box>
          <LinearProgress
            value={agreementProgressBar}
            variant="determinate"
            aria-label="agreement progress bar"
          />
        </Box>
      )}
      <Box
        sx={{
          maxHeight: theme.spacing(50),
          minHeight: theme.spacing(6),
          overflowY: 'scroll',
          border: `1px solid ${theme.palette.surface.lines}`,
          borderRadius: requireScrollToAccept
            ? theme.spacing(0, 0, 1, 1)
            : theme.spacing(1),
          padding: theme.spacing(5, 4, 2, 4),
          fontSize: '14px',
        }}
        ref={(el) => {
          setRefAssigned(true);
          agreementBodyRef.current = el;
        }}
        onScroll={() => {
          scrollTracker(
            agreementBodyRef,
            setHasUserScrolledAgreement,
            setAgreementProgressBar
          );
        }}
      >
        {agreementText}
      </Box>
      <Box sx={{ marginTop: theme.spacing(2) }}>
        <AccessAgreementCheckbox
          checked={hasUserEnabledCheckbox}
          label={checkboxText}
          onClick={handleClickCheckbox}
          disabled={!hasUserScrolledAgreement}
        />
      </Box>
    </>
  );
}

AccessAgreementsDialogContent.propTypes = {
  agreementText: oneOfType([string, node]).isRequired,
  checkboxText: string.isRequired,
  requireScrollToAccept: bool.isRequired,
  hasUserEnabledCheckbox: bool.isRequired,
  setHasUserEnabledCheckbox: func.isRequired,
  hasUserScrolledAgreement: bool.isRequired,
  setHasUserScrolledAgreement: func.isRequired,
  handleClickCheckbox: func.isRequired,
  initialState: shape({ scrolled: bool }),
};

AccessAgreementsDialogContent.defaultProps = {
  initialState: undefined,
};

export default AccessAgreementsDialogContent;
