import {
  sortKeepAllObjBeginning,
  flagshipWlp,
  removeDuplicates,
} from 'services/document_manager';
import axios from 'axios';
import { getRequest } from 'actions/utils';

const URL = 'white_label_partners';
export const fetchWlpList = ({ onSuccess = null }) => getRequest({ url: URL, onSuccess });


export const getWhiteLabelPartners = (query) => {
  const url = 'white_label_partners';
  return axios.get(url, {
    withCredentials: true,
    params: {
      query,
    },
  }).then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};



export const sortWlp = (data = []) => {
  let wlps = [...data];
  wlps.push(flagshipWlp);
  wlps = removeDuplicates(wlps);
  return wlps.sort(sortKeepAllObjBeginning);
};
