/* eslint-disable max-statements */
import { getRequest } from 'actions/utils';

import {
  getMostRecentStatementInSelectedCurrency,
  getSelectedCurrencyCode,
  getDataSetsLoaded,
  canSeeInvestmentDashboardTransactions,
  allStatementsInFundCurrency,
} from 'reducers/investment_dashboard_reducers/selectors';
import { filterProps } from 'hooks/investment_dashboard/useTransactionsFilter';

import { idSetGeneralLedgerTransactionsData, idSetAllTransactions, idUpdateDataSetsLoaded } from './helpers/constants';

const idGenerateAllTransactionsObject = (transactions) => {
  return (dispatch, getState) => {
    const state = getState();
    const allStatements = allStatementsInFundCurrency(state);

    if (allStatements) {
      transactions.transactions_periods = allStatements.map((statement) => `Q${statement.quarter} ${statement.year}`);
    }

    dispatch(idSetAllTransactions(transactions));
  };
};

const idFetchGeneralLedgerTransactions = (ids) => (dispatch, getState) => {
  const state = getState();
  const effectiveDate = getMostRecentStatementInSelectedCurrency(state).quarter_end_date;
  const selectedCurrency = getSelectedCurrencyCode(state);

  const url = 'investment_dashboard/general_ledger_transactions';
  const params = {
    pcap_date: effectiveDate,
    selected_currency: selectedCurrency,
    ...ids,
  };

  return dispatch(
    getRequest({
      url,
      params,
      onSuccess: idSetGeneralLedgerTransactionsData,
    })
  );
};

const idFetchGeneralLedgerTransactionsList = (params) =>
  getRequest({
    url: 'investment_dashboard/general_ledger_transactions/list_transactions',
    params: {
      ...filterProps(),
      ...params,
    },
    onSuccess: (data) => idGenerateAllTransactionsObject(data),
  });

const idLoadGeneralLedgerTransactionsDataSet = (ids) => (dispatch, getState) => {
  const state = getState();
  const dataSetsLoaded = getDataSetsLoaded(state);
  const canSeeIDTransactions = canSeeInvestmentDashboardTransactions(state);

  if (dataSetsLoaded.transactionsDataSet) return;

  dispatch(idFetchGeneralLedgerTransactions(ids));

  if (canSeeIDTransactions) {
    dispatch(idFetchGeneralLedgerTransactionsList(ids));
  }

  dispatch(idUpdateDataSetsLoaded({ transactionsDataSet: true }));
};

export {
  idGenerateAllTransactionsObject,
  idFetchGeneralLedgerTransactions,
  idFetchGeneralLedgerTransactionsList,
  idLoadGeneralLedgerTransactionsDataSet,
};
