import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { useSnackbar } from '@icapitalnetwork/supernova-hooks';
import { NotificationMessage } from 'components/shared/notifications/NotificationMessage';
export function useDefaultNotifications() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const enqueueMessage = useCallback((variant) => (message, options) => {
        const finalMessage = options.body ? _jsx(NotificationMessage, { title: message, body: options.body }) : message;
        const id = enqueueSnackbar(finalMessage, Object.assign(Object.assign({ variant, anchorOrigin: { vertical: 'top', horizontal: 'right' }, onCloseAction: () => closeSnackbar(id) }, options), { onActionClick: () => {
                if (options.onActionClick) {
                    options.onActionClick(() => closeSnackbar(id));
                }
            } }));
    }, [closeSnackbar, enqueueSnackbar]);
    const enqueueSnackbarDefault = useCallback((message, options = {}) => enqueueMessage('default')(message, options), [enqueueMessage]);
    const enqueueSnackbarError = useCallback((message, options = {}) => enqueueMessage('error')(message, options), [enqueueMessage]);
    const enqueueSnackbarSuccess = useCallback((message, options = {}) => enqueueMessage('success')(message, options), [enqueueMessage]);
    const enqueueSnackbarInfo = useCallback((message, options = {}) => enqueueMessage('info')(message, options), [enqueueMessage]);
    const enqueueSnackbarWarning = useCallback((message, options = {}) => enqueueMessage('warning')(message, options), [enqueueMessage]);
    return {
        closeSnackbar,
        enqueueSnackbarDefault,
        enqueueSnackbarError,
        enqueueSnackbarSuccess,
        enqueueSnackbarInfo,
        enqueueSnackbarWarning,
    };
}
