import React from 'react';
import Stack from '@icapitalnetwork/supernova-core-v2/Stack';
import { useTheme } from '@mui/material';
import HomeDisclaimer from 'modules/landing_page/components/home/Disclaimer';
import BannerHandler from './BannerHandler';

const HomepageMFE = () => {
  const theme = useTheme();
  const backgroundColor = theme.palette.grey[50];

  return (
    <Stack>
      <BannerHandler />
      <div style={{ minHeight: '800px' }} id="single-spa-application:unified-homepage" />
      <Stack sx={{ backgroundColor }}>
        <HomeDisclaimer />
      </Stack>
    </Stack>
  );
};

export default HomepageMFE;
