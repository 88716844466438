import configuration from 'configuration';
function getPusherKey() {
    if (configuration.RAILS_ENV === 'development' && configuration.DISABLE_PUSHER === true) {
        return null;
    }
    return configuration.PUSHER_API_KEY;
}
function getPusherOptions() {
    const devOptions = { cluster: configuration.PUSHER_CLUSTER };
    if (configuration.RAILS_ENV === 'development' || configuration.RAILS_ENV === 'test') {
        devOptions.wsHost = configuration.PUSHER_WS_HOST;
        devOptions.wsPort = parseInt(configuration.PUSHER_WS_PORT, 10);
        devOptions.httpHost = configuration.PUSHER_HOST;
        devOptions.httpPort = parseInt(configuration.PUSHER_PORT, 10);
        devOptions.disableStats = true;
        devOptions.forceTLS = false;
    }
    return devOptions;
}
export function getPusherConfig() {
    return {
        pusherKey: getPusherKey(),
        options: getPusherOptions(),
    };
}
