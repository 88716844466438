/* eslint-disable max-statements */
import axios from 'axios';
import notificationService from 'services/notifications';

const notificationParams = (message) => [message.title, message.message, message.timeout];

/**
 * Helper for api requests that calls "axios" service.
 * If a notification message is supplied, call the notification service
 *
 * @typedef {Object} ApiRequestConfig
 * @property {Object} [config.successMessage] success message object for notificationService (THEN)
 * @property {Object} [config.errorMessage] error message object for notificationService (CATCH)
 * @property {Object} [config.infoMessage] info message object for notificationService (THEN)
 * @property {function} [config.onSuccess] action callback for successful request (can be a thunk)
 * @property {function} [config.onFailure] action callback for failed request (can be a thunk)
 * @property {Object} [config.customInterceptors] for request cancellation
 * @property {Object} [config.cancelInstance] for request cancellation
 *
 * @typedef {import("axios").AxiosRequestConfig} AxiosRequestConfig
 * @typedef {ApiRequestConfig & AxiosRequestConfig} GenericApiRequestConfig
 *
 * @param {GenericApiRequestConfig} config
 */
export const apiRequest = ({
  url,
  method = 'get',
  withCredentials = true,
  params = {},
  data = {},
  successMessage = null,
  errorMessage = null,
  infoMessage = null,
  onSuccess = null,
  onFailure = null,
  customInterceptors = null,
  cancelInstance = { instance: undefined },
  responseType = 'json',
  headers,
}) => {
  const CancelToken = axios.CancelToken;

  if (cancelInstance.instance !== undefined && cancelInstance.instance.constructor === Function) {
    cancelInstance.instance();
    cancelInstance.instance = undefined;
  }

  let axiosInstance = axios;

  if (customInterceptors) {
    axiosInstance = axios.create();

    if (customInterceptors.request) {
      axiosInstance.interceptors.request.use(customInterceptors.request.success, customInterceptors.request.error);
    }

    if (customInterceptors.response) {
      axiosInstance.interceptors.response.use(customInterceptors.response.success, customInterceptors.response.error);
    }
  }

  return axiosInstance({
    headers,
    url,
    method,
    withCredentials,
    params,
    data,
    responseType,
    cancelToken: new CancelToken((c) => {
      cancelInstance.instance = c;
    }),
  })
    .then((response) => {
      if (onSuccess) {
        onSuccess(response.data);
      }

      if (successMessage) {
        notificationService.notifySuccess(...notificationParams(successMessage));
      }
      if (infoMessage) {
        notificationService.notifyInfo(...notificationParams(infoMessage));
      }
      return response.data;
    })
    .catch((err) => {
      // if we have a cancel instance and the error was thrown by the cancelToken
      // we should ignore the request
      if (cancelInstance.instance !== undefined && axios.isCancel(err)) return;

      if (onFailure) {
        onFailure(err);
      }

      if (errorMessage) {
        notificationService.notifyError(...notificationParams(errorMessage));
      }
      throw err;
    });
};

// Aliases for different request methods
/** @param {GenericApiRequestConfig} config */
export const getRequest = (config) => apiRequest({ ...config, method: 'get' });

/** @param {GenericApiRequestConfig} config */
export const postRequest = (config) => apiRequest({ ...config, method: 'post' });

/** @param {GenericApiRequestConfig} config */
export const putRequest = (config) => apiRequest({ ...config, method: 'put' });

/** @param {GenericApiRequestConfig} config */
export const patchRequest = (config) => apiRequest({ ...config, method: 'patch' });

/** @param {GenericApiRequestConfig} config */
export const deleteRequest = (config) => apiRequest({ ...config, method: 'delete' });

export default apiRequest;
